<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div
      class="
        main-content-header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h1 class="card-title">Productos</h1>

      <router-link
        :to="{ name: 'ProductsCreate' }"
        type="button"
        class="btn btn-info"
      >
        Registrar producto
        <i class="fas fa-plus ml-2"></i>
      </router-link>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-4 position-relative" v-else>
      <div class="table-options">
        <button
          type="button"
          class="btn btn-link text-dark text-uppercase font-weight-bold"
          @click="$bvModal.show('modal-filters')"
        >
          <i class="fas fa-filter"></i>
          Filtrar
        </button>

        <form @submit.prevent="search()" class="form-search my-0 py-0">
          <div
            class="
              form-group
              mb-0
              d-flex
              justify-content-end
              d-flex
              align-items-center
              h-100
            "
          >
            <input
              type="search"
              placeholder="Buscar por nombre producto"
              class="form-control search-field"
              v-model="query"
            />

            <button class="btn btn-info py-2 ml-2">Buscar</button>
          </div>
        </form>
      </div>

      <div class="table-container">
        <table class="main-table" width="100%">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <p class="text-left pl-2">Nombre</p>
              </th>
              <th scope="col">Estado</th>
              <th scope="col">Relevancia</th>
              <!-- <th scope="col">Stock actual</th> -->
              <th scope="col">Precio</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item.id">
              <td class="cell">
                <img
                  :src="item.foto_real.url"
                  :alt="item.nombre"
                  class="table-img"
                />
              </td>
              <td class="cell text">
                <p class="table-name">
                  {{ item.nombre }}
                </p>
              </td>
              <td class="cell text text-uppercase text-center">
                <b-form-checkbox
                  v-model="items[index].estado"
                  name="check-button"
                  switch
                  @change="updateStatus('status', item)"
                  class="ml-2"
                >
                </b-form-checkbox>

                <span class="small">{{
                  item.estado ? "Activado" : "Desactivado"
                }}</span>
              </td>
              <td class="cell text text-uppercase text-center">
                <b-form-checkbox
                  v-model="items[index].favoritos"
                  name="check-button"
                  switch
                  @change="updateStatus('´favoritos', item)"
                  class="ml-2"
                >
                </b-form-checkbox>
                <span class="small">{{
                  item.favoritos ? "Destacado" : "No destacado"
                }}</span>
              </td>
              <!-- <td class="cell">
                <div class="text-center">
                  {{ item.stok_real }}
                </div>
              </td> -->
              <td class="cell text-center">
                <div
                  class="table-discount-price d-flex justify-content-center"
                  v-if="item.precio_descuento"
                >
                  <span class="real text-muted">{{
                    setPrice(item.precio_real)
                  }}</span>
                  <span class="d-inline-block font-weight-bold ml-2">{{
                    setPrice(item.precio_descuento)
                  }}</span>
                </div>

                <span class="text-muted font-weight-bold" v-else>{{
                  setPrice(item.precio_real)
                }}</span>
              </td>
              <td class="cell text-center">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="custom-dropdown"
                  no-caret
                  right
                  :no-flip="true"
                  menu-class="dropdown-options"
                  @show="opacity = true"
                  @hide="opacity = false"
                >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item href="#" @click.prevent="viewProduct(item)"
                    >Ver producto</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'ProductsUpdate',
                        params: { slug: item.slug },
                      })
                    "
                    >Editar</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click.prevent="deleteItem(item.id, index)"
                    >Eliminar</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-container d-flex justify-content-center mt-5" v-if="items.length">
        <b-pagination
          v-model="page"
          :total-rows="nroTotalItems"
          :per-page="number_paginate"
          @change="paginate($event)"
        ></b-pagination>
      </div>

      <ButtonToTop elementId="main-container" />
    </section>

    <b-modal id="modal-filters" title="Filtrar" hide-footer centered>
      <form @submit.prevent="filter()">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nro-items">Mostrar filas</label>
              <select
                id="nro-items"
                class="form-control select-options"
                v-model="number_paginate"
              >
                <option :value="item" v-for="item in paginateItems" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="products-status">Estado de producto</label>
              <select
                id="products-status"
                class="form-control select-options"
                v-model="status"
              >
                <option value="">Todos</option>
                <option value="Activado">Activados</option>
                <option value="Desactivado">Desactivados</option>
              </select>
            </div>
          </div>
        </div>

        <section class="text-right mt-3 px-4">
          <a
            href=""
            class="text-success mr-4"
            @click="clear()"
            v-if="number_paginate != 5 || status != ''"
            >Limpiar filtros</a
          >

          <input type="submit" class="btn btn-primary px-4" value="Filtrar" />
        </section>
      </form>
    </b-modal>

    <b-modal
      id="modal-details"
      :title="`Detalles de producto #${selectedItem.id}`"
      hide-footer
      centered
      size="xl"
      class="modal-details"
    >
      <ProductDetail :item="selectedItem" />
    </b-modal>
  </section>
</template>

<script>
import { appConfig } from '../../../../app-config'

import {
  PAGINATE_ITEMS,
  priceFormat,
} from "@/utils/static-values.js";

import GetProductos from "../graphql/queries/GetProductos.gql";
import GetProductosRelacionados from "../graphql/queries/GetProductosRelacionados.gql";
import UpdateProducto from "../graphql/mutations/UpdateProducto.gql";
import DeleteProducto from "../graphql/mutations/DeleteProducto.gql";

import { Loading, ButtonToTop } from "@/components";

import ProductDetail from "../components/ProductDetails.vue";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      page: 1,
      number_paginate: 10,
      nroTotalItems: null,
      status: "",
      loading: true,
      paginateItems: PAGINATE_ITEMS,
      selectedItem: {},
      query: "",
      changeQuery: false
    };
  },
  watch: {
    query: function (val, oldVal) {
      if (!val.length) {
        this.query = null
        this.page = 1
        this.$router.push({name: this.$route.name})
        this.init();
      }

      if(val != oldVal) {
        this.changeQuery = true
      }
    },
  },
  mounted() {
    if(this.$route.query.q) this.query = this.$route.query.q
    if(this.$route.query.page) this.page = this.$route.query.page
    this.init();
  },
  components: {
    Loading,
    ButtonToTop,
    ProductDetail,
  },
  methods: {
    init() {
      this.getItems();
    },
    async getItems() {
      let response = await this.$apollo.query({
        query: GetProductos,
        variables: {
          number_paginate: this.number_paginate,
          page: this.page,
          estado: this.status || "",
        },
        fetchPolicy: "no-cache",
      });

      this.nroTotalItems = response.data.GetProductos.NroItems;
      this.items = response.data.GetProductos.data;
      this.changeQuery = false

      this.items.forEach((item) => {
        !item.estado || item.estado === 'Desactivado' ? (item.estado = false) : (item.estado = true)

        !item.favoritos || item.favoritos == '0'
          ? (item.favoritos = false)
          : (item.favoritos = true)
      });

      this.loading = false;
    },
    paginate(event) {
      this.page = event;
      this.$router.push({name: this.$route.name, query: {page: event}})

      if(this.query) {
        this.search();
      } else {
        this.getItems();
      }

      const posts = document.getElementById("main-container");

      this.$smoothScroll({
        scrollTo: posts,
        duration: 1000,
        offset: -100,
        updateHistory: false,
      });
    },
    changeNroItems() {
      this.page = 1;
      this.getItems();
    },
    filter() {
      if(this.query) {
        this.search()
      } else {
        this.getItems();
      }

      this.$bvModal.hide("modal-filters");
    },
    clear() {
      this.number_paginate = 5;
      this.status = "";

      this.$bvModal.hide("modal-filters");
    },
    setPrice(price) {
      return priceFormat(price);
    },
    deleteItem(id, index) {
      this.$swal({
        title: "Eliminar producto",
        text: "El producto se eliminará de forma permanente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$apollo.mutate({
            mutation: DeleteProducto,
            variables: {
              input: {
                id,
              },
            },
          });

          this.items.splice(index, 1);

          this.$toast.success("Producto eliminado");
        }
      });
    },
    showDetails(item) {
      this.selectedItem = item;

      this.$bvModal.show("modal-details");
    },
    async updateStatus(type, item) {
      let status = item.estado ? "Activado" : "Desactivado";
      let favoritos = item.favoritos ? 1 : 0;

      let input = {
        id: parseInt(item.id),
      };

      type === "status"
        ? (input.estado = status)
        : (input.favoritos = parseInt(favoritos));

      await this.$apollo.mutate({
        mutation: UpdateProducto,
        variables: {
          input,
        },
      });

      this.$toast.success("Estado actualizado");
    },
    async search() {
      if (!this.query.length) return;
      if(this.changeQuery) this.page = 1

      this.$router.push({name: this.$route.name, query: {q: this.query, page: this.page}})

      let response = await this.$apollo.query({
        query: GetProductosRelacionados,
        variables: {
          number_paginate: this.number_paginate,
          page: this.page,
          palabraClave: this.query,
          estado: this.status || ""
        },
      });

      this.nroTotalItems = response.data.GetProductosRelacionados.NroItems;
      this.items = response.data.GetProductosRelacionados.data;
      this.changeQuery = false

      this.items.forEach((item) => {
        !item.estado || item.estado === 'Desactivado' ? (item.estado = false) : (item.estado = true);
        
        !item.favoritos || item.favoritos == '0'
          ? (item.favoritos = false)
          : (item.favoritos = true);
      });
    },
    viewProduct(item) {
      window.open(`${appConfig.tiendaUrl}/productos/${item.slug}`)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 280px;
    }
  }
}
</style>