<template>
  <section>
      <router-view></router-view>
  </section>
</template>

<script>
export default {
    name: 'HomeView'
}
</script>

<style>

</style>