<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">
        {{ itemId ? "Actualizar" : "Registrar" }} billetera digital
      </h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <b-form @submit.prevent="submit">
              <b-form-group id="titulo" label="Nombre" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.titulo"
                  type="text"
                  placeholder="Ingresar nombre"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.titulo.$error && !$v.item.titulo.required"
                />
              </b-form-group>

              <b-form-group
                id="nroCelular"
                label="Número afiliado"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="item.nroCelular"
                  type="text"
                  placeholder="Ingresar número afiliado"
                ></b-form-input>

                <FormError
                  text="El número afiliado es requerido"
                  :show="
                    $v.item.nroCelular.$error && !$v.item.nroCelular.required
                  "
                />
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <section class="mt-4">
                    <div
                      class="d-flex justify-content-center"
                      v-if="item.foto_principal"
                    >
                      <img
                        :src="item.foto_principal.url"
                        :alt="item.foto_principal.titulo"
                        class="preview-image border"
                      />
                    </div>

                    <div class="text-center mt-3" v-if="itemId">
                      <a href="" @click.prevent="showModalImages('foto_principal')"
                        >Actualizar imagen</a
                      >
                    </div>

                    <div class="select-image text-center mt-3" v-else>
                      <a
                        href=""
                        class="btn btn-outline-info"
                        @click.prevent="showModalImages('foto_principal')"
                        >Asignar imagen destacada</a
                      >
                    </div>

                    <FormError
                      text="La imagen es requerida"
                      :show="
                        $v.item.foto_principal.$error &&
                        !$v.item.foto_principal.required
                      "
                    />
                  </section>
                </div>

                <div class="col-md-6">
                  <section class="mt-4">
                    <div
                      class="d-flex justify-content-center"
                      v-if="item.fotoQr"
                    >
                      <img
                        :src="item.fotoQr.url"
                        :alt="item.fotoQr.titulo"
                        class="preview-image border"
                      />
                    </div>

                    <div class="text-center mt-3" v-if="itemId">
                      <a href="" @click.prevent="showModalImages('fotoQr')"
                        >Actualizar imagen</a
                      >
                    </div>

                    <div class="select-image text-center mt-3" v-else>
                      <a
                        href=""
                        class="btn btn-outline-info"
                        @click.prevent="showModalImages('fotoQr')"
                        >Asignar imagen QR</a
                      >
                    </div>
                  </section>
                </div>
              </div>

              <div
                class="
                  form-group
                  d-flex
                  justify-content-between
                  align-items-center
                  text-center
                  mt-5
                "
              >
                <router-link :to="{ name: 'MobileCashList' }" class="text-danger">
                  <i class="fas fa-chevron-left"></i>
                  Cancelar
                </router-link>

                <button
                  type="submit"
                  class="btn btn-success px-4 ml-3"
                  :disabled="loadingAction"
                >
                  {{ itemId ? "Actualizar" : "Crear" }}
                </button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <ImageManagement @selected="selectedImage($event)" />
  </section>
</template>

<script>
import GetIdEfectivo from "../graphql/queries/GetIdEfectivo.gql";
import CreateEfectivoMovil from "../graphql/mutations/CreateEfectivoMovil.gql";
import UpdateEfectivoMovil from "../graphql/mutations/UpdateEfectivoMovil.gql";

import { Loading, ImageManagement, FormError } from "@/components";

import { required } from "vuelidate/lib/validators";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      loading: true,
      itemId: null,
      item: {
        titulo: null,
        foto_principal: null,
        fotoQr: null,
        nroCelular: null,
      },
      loadingAction: false,
      typeUploadImage: null,
      oldImages: []
    };
  },
  mounted() {
    this.init();
  },
  validations: {
    item: {
      titulo: {
        required,
      },
      nroCelular: {
        required,
      },
      foto_principal: {
        required,
      }
    },
  },
  components: {
    Loading,
    ImageManagement,
    FormError,
  },
  methods: {
    init() {
      this.itemId = this.$route.params.id;

      if (this.itemId) {
        this.getItemId();
      } else {
        this.loading = false;
      }
    },
    async getItemId() {
      let response = await this.$apollo.query({
        query: GetIdEfectivo,
        variables: {
          Id_efectivo: this.itemId,
        },
        fetchPolicy: 'no-cache'
      });

      let data = response.data.GetIdEfectivo;

      if (data) {
        this.item = {
          ...data,
        };
      }

      this.loading = false;
    },
    showModalImages(type) {
      this.typeUploadImage = type

      if (this.typeUploadImage === "foto_principal") {
        this.oldImages = [this.item.foto_principal]
      } else if(this.typeUploadImage === 'fotoQr') {
        this.oldImages = [this.item.fotoQr]
      }

      this.$bvModal.show('modal-images')
    },
    selectedImage(data) {
      if(this.typeUploadImage === 'foto_principal') {
        this.item.foto_principal = data[0]
      } else {
        this.item.fotoQr = data[0]
      }
      
      this.$bvModal.hide("modal-images");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.loadingAction = true;

      let input = {
        ...this.item,
      };

      input.foto_principal = input.foto_principal.id;
      input.fotoQr ? input.fotoQr = input.fotoQr.id : input.fotoQr = null

      let mutation = this.itemId ? UpdateEfectivoMovil : CreateEfectivoMovil;

      await this.$apollo.mutate({
        mutation,
        variables: {
          input,
        },
      });

      this.loadingAction = false;
      this.$router.push({ name: "MobileCashList" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.preview-image {
  max-width: 100%;
  max-height: 220px;
}
</style>