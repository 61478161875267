<template>
  <section class="main-nav pt-3" :class="{ 'main-nav--active': showSidebar }">
    <div class="text-center">
      <img
        :src="appConfig.logo"
        alt="Logo empresa"
        class="logo"
      />
    </div>

    <section class="mt-3 p-1">
      <div class="accordion" role="tablist">
        <div>
          <b-button
            @click="redirectTo({ name: 'Home' })"
            block
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.path === '/' }"
          >
            <p class="my-0">
              <i class="fas fa-home"></i>
              <span class="d-inline-block ml-3">Inicio</span>
            </p>

            <!-- <i class="fas fa-angle-right"></i> -->
          </b-button>
        </div>

        <div>
          <b-button
            block
            v-b-toggle.images
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'Images' }"
            @click="$router.push({ name: 'Images' })"
          >
            <p class="my-0">
              <i class="far fa-list-alt"></i>
              <span class="d-inline-block ml-3">Imágenes</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button>

          <b-collapse id="images" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'Images' }"
                class="accordion-link"
                :class="{ 'accordion-link-active': $route.name === 'Images' }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar imágenes
              </router-link>

              <router-link
                :to="{ name: 'ImagesUpload' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'ImagesUpload',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>
                Registrar
              </router-link>
            </div>
          </b-collapse>
        </div>

        <div>
          <b-button
            block
            v-b-toggle.ads
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'BannersList' }"
            @click="$router.push({ name: 'BannersList' })"
          >
            <p class="my-0">
              <i class="fas fa-location-arrow"></i>
              <span class="d-inline-block ml-3">Banner</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button>

          <b-collapse id="ads" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'BannersList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'BannersList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar slides
              </router-link>

              <router-link
                :to="{ name: 'BannersCreate' }"
                :class="{
                  'accordion-link-active': $route.name === 'BannersCreate',
                }"
                class="accordion-link"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>
                Agregar slide
              </router-link>
            </div>
          </b-collapse>
        </div>

        <div>
          <b-button
            block
            v-b-toggle.products
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'ProductsList' }"
            @click="$router.push({ name: 'ProductsList' })"
          >
            <p class="my-0">
              <i class="far fa-list-alt"></i>
              <span class="d-inline-block ml-3">Productos</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button>

          <b-collapse id="products" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'ProductsList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'ProductsList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar productos
              </router-link>

              <router-link :to="{name: 'ProductsCreate'}" class="accordion-link" :class="{
                  'accordion-link-active': $route.name === 'ProductsCreate',
                }">
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>
                Registrar producto
              </router-link>

              <router-link
                :to="{ name: 'ProductsCategoryList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active':
                    $route.name === 'ProductsCategoryList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar categorías
              </router-link>
            </div>
          </b-collapse>
        </div>

        <div>
          <b-button
            block
            v-b-toggle.vendors
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'VendorsList' }"
            @click="$router.push({ name: 'VendorsList' })"
          >
            <p class="my-0">
              <i class="far fa-list-alt"></i>
              <span class="d-inline-block ml-3">Vendedores</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button>

          <b-collapse id="vendors" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'VendorsList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'VendorsList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar vendedores
              </router-link>

              <router-link :to="{name: 'VendorsCreate'}" class="accordion-link" :class="{
                  'accordion-link-active': $route.name === 'VendorsCreate',
                }">
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>
                Registrar vendedor
              </router-link>
            </div>
          </b-collapse>
        </div>

        <!-- <div>
          <b-button
            @click="$router.push({ name: 'OrdersList' })"
            block
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'OrdersList' }"
          >
            <p class="my-0">
              <i class="fas fa-dolly-flatbed"></i>
              <span class="d-inline-block ml-3">Pedidos</span>
            </p>
          </b-button>
        </div> -->

        <div>
          <!-- <b-button
            block
            v-b-toggle.districts
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{
              'accordion-item-active': $route.name === 'DistrictsList',
            }"
            @click="$router.push({ name: 'DistrictsList' })"
          >
            <p class="my-0">
              <i class="fas fa-map-marker-alt"></i>
              <span class="d-inline-block ml-3">Distritos de envío</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button> -->

          <b-collapse id="districts" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'DistrictsList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'DistrictsList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Listar distritos
              </router-link>
            </div>
          </b-collapse>
        </div>

        <div>
          <!-- <b-button
            block
            v-b-toggle.finance
            variant="none"
            class="
              accordion-item
              d-flex
              justify-content-between
              align-items-center
            "
            :class="{ 'accordion-item-active': $route.name === 'BanksList' }"
            @click="$router.push({ name: 'BanksList' })"
          >
            <p class="my-0">
              <i class="fas fa-map-marker-alt"></i>
              <span class="d-inline-block ml-3">Finanzas</span>
            </p>

            <i class="fas fa-angle-down"></i>
          </b-button> -->

          <b-collapse id="finance" accordion="my-accordion" role="tabpanel">
            <div class="d-flex flex-column px-4">
              <router-link
                :to="{ name: 'BanksList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'BanksList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>

                Bancos
              </router-link>

              <router-link
                :to="{ name: 'MobileCashList' }"
                class="accordion-link"
                :class="{
                  'accordion-link-active': $route.name === 'MobileCashList',
                }"
              >
                <span class="small">
                  <i class="fas fa-circle-notch"></i>
                </span>
                Efectivo móvil
              </router-link>
            </div>
          </b-collapse>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { appConfig } from '../../../app-config.js'

export default {
  name: "MainNav",
  data() {
    return {
      appConfig
    }
  },
  props: {
    showSidebar: Boolean,
  },
  methods: {
    redirectTo(route) {
      this.$router.push(route);
      // this.$root.$emit("bv::toggle::collapse", "products");
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.main-nav {
  width: 100%;
  height: 100%;
  position: fixed;

  @media screen and (min-width: 992px) {
    max-width: $sidebar-width;
  }

  .logo {
    max-width: 120px;
  }

  .accordion {
    &-item {
      font-size: 1rem;
      color: rgba($secondary, 0.9);
      font-weight: 500;
      text-align: left;
      padding: 0.625rem 1rem;

      &:hover,
      &:active {
        background-color: $light;
        color: $secondary;
      }

      &-active {
        background-color: rgba($primary, 0.2);
        color: $primary;

        -webkit-box-shadow: 0px 0px 5px -1px rgba($primary, 0.53) !important;
        -moz-box-shadow: 0px 0px 5px -1px rgba($primary, 0.53) !important;
        box-shadow: 0px 0px 5px -1px rgba($primary, 0.53) !important;

        &:hover {
          background-color: rgba($primary, 0.2);
          color: $primary;
        }
      }
    }

    &-link {
      color: rgba($secondary, 0.9);
      font-weight: 500;
      text-align: left;
      padding: 0.7rem 0.3rem;
      margin: 0;
      text-decoration: none;
      display: flex;
      align-items: center;

      &-active {
        color: $secondary;
        font-weight: 700;
      }
    }

    span.small {
      font-size: 0.3rem;
      display: inline-block;
      margin-right: 0.9rem;
    }
  }
}
</style>