var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-nav pt-3",class:{ 'main-nav--active': _vm.showSidebar }},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.appConfig.logo,"alt":"Logo empresa"}})]),_c('section',{staticClass:"mt-3 p-1"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',[_c('b-button',{staticClass:"accordion-item d-flex justify-content-between align-items-center",class:{ 'accordion-item-active': _vm.$route.path === '/' },attrs:{"block":"","variant":"none"},on:{"click":function($event){return _vm.redirectTo({ name: 'Home' })}}},[_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"fas fa-home"}),_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v("Inicio")])])])],1),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.images",modifiers:{"images":true}}],staticClass:"accordion-item d-flex justify-content-between align-items-center",class:{ 'accordion-item-active': _vm.$route.name === 'Images' },attrs:{"block":"","variant":"none"},on:{"click":function($event){return _vm.$router.push({ name: 'Images' })}}},[_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"far fa-list-alt"}),_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v("Imágenes")])]),_c('i',{staticClass:"fas fa-angle-down"})]),_c('b-collapse',{attrs:{"id":"images","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{ 'accordion-link-active': _vm.$route.name === 'Images' },attrs:{"to":{ name: 'Images' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar imágenes ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'ImagesUpload',
              },attrs:{"to":{ name: 'ImagesUpload' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Registrar ")])],1)])],1),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.ads",modifiers:{"ads":true}}],staticClass:"accordion-item d-flex justify-content-between align-items-center",class:{ 'accordion-item-active': _vm.$route.name === 'BannersList' },attrs:{"block":"","variant":"none"},on:{"click":function($event){return _vm.$router.push({ name: 'BannersList' })}}},[_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"fas fa-location-arrow"}),_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v("Banner")])]),_c('i',{staticClass:"fas fa-angle-down"})]),_c('b-collapse',{attrs:{"id":"ads","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'BannersList',
              },attrs:{"to":{ name: 'BannersList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar slides ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'BannersCreate',
              },attrs:{"to":{ name: 'BannersCreate' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Agregar slide ")])],1)])],1),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.products",modifiers:{"products":true}}],staticClass:"accordion-item d-flex justify-content-between align-items-center",class:{ 'accordion-item-active': _vm.$route.name === 'ProductsList' },attrs:{"block":"","variant":"none"},on:{"click":function($event){return _vm.$router.push({ name: 'ProductsList' })}}},[_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"far fa-list-alt"}),_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v("Productos")])]),_c('i',{staticClass:"fas fa-angle-down"})]),_c('b-collapse',{attrs:{"id":"products","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'ProductsList',
              },attrs:{"to":{ name: 'ProductsList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar productos ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'ProductsCreate',
              },attrs:{"to":{name: 'ProductsCreate'}}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Registrar producto ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active':
                  _vm.$route.name === 'ProductsCategoryList',
              },attrs:{"to":{ name: 'ProductsCategoryList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar categorías ")])],1)])],1),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.vendors",modifiers:{"vendors":true}}],staticClass:"accordion-item d-flex justify-content-between align-items-center",class:{ 'accordion-item-active': _vm.$route.name === 'VendorsList' },attrs:{"block":"","variant":"none"},on:{"click":function($event){return _vm.$router.push({ name: 'VendorsList' })}}},[_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"far fa-list-alt"}),_c('span',{staticClass:"d-inline-block ml-3"},[_vm._v("Vendedores")])]),_c('i',{staticClass:"fas fa-angle-down"})]),_c('b-collapse',{attrs:{"id":"vendors","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'VendorsList',
              },attrs:{"to":{ name: 'VendorsList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar vendedores ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'VendorsCreate',
              },attrs:{"to":{name: 'VendorsCreate'}}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Registrar vendedor ")])],1)])],1),_c('div',[_c('b-collapse',{attrs:{"id":"districts","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'DistrictsList',
              },attrs:{"to":{ name: 'DistrictsList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Listar distritos ")])],1)])],1),_c('div',[_c('b-collapse',{attrs:{"id":"finance","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"d-flex flex-column px-4"},[_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'BanksList',
              },attrs:{"to":{ name: 'BanksList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Bancos ")]),_c('router-link',{staticClass:"accordion-link",class:{
                'accordion-link-active': _vm.$route.name === 'MobileCashList',
              },attrs:{"to":{ name: 'MobileCashList' }}},[_c('span',{staticClass:"small"},[_c('i',{staticClass:"fas fa-circle-notch"})]),_vm._v(" Efectivo móvil ")])],1)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }