<template>
  <div class="container" v-if="item">
    <div class="row">
      <div class="col-md-6">
        <h1 class="title">{{ item.nombre }}</h1>

        <p class="small text-muted mb-4">
          Producto registrado
          {{ $dayjs(item.created_at).format("DD/MM/YYYY") }}
        </p>

        <p>
          {{ item.descripcionCorta }}
        </p>

        <div v-html="item.descripcionLarga"></div>

        <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Categoría:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.data.nombre }}</p>
          </div>
        </div>

        <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Precio real:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.precio_real }}</p>
          </div>
        </div>

        <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Precio con descuento:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.precio_descuento }}</p>
          </div>
        </div>

        <!-- <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Stock real:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.stok_real }}</p>
          </div>
        </div> -->

        <!-- <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Stock mínimo:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.stok_minimo }}</p>
          </div>
        </div> -->

        <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Palabras clave:</label>
          </div>

          <div class="col-md-8">
            <p>{{ item.keywords }}</p>
          </div>
        </div>

        <div class="row border-bottom py-1">
          <div class="col-md-4">
            <label>Estado:</label>
          </div>

          <div class="col-md-8">
            <!-- <span
              :class="`badge text-white bg-${
                getStatusValue(item.estado).badgeColor
              }`"
              >{{ getStatusValue(item.estado).value }}</span
            > -->
          </div>
        </div>

        <div class="row py-1 mt-3">
          <div class="col-md-4">
            <label>Relevancia:</label>
          </div>

          <div class="col-md-8">
                        <!-- <span
              :class="`badge text-white bg-${
                getFavoritesStatus(item.favoritos).badgeColor
              }`"
              >{{ getFavoritesStatus(item.favoritos).value }}</span
            > -->
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <section class="shadow-card text-left">
              <label>Imagen principal</label>
              <img :src="item.foto_real.url" alt="" class="img-fluid" />
            </section>
          </div>

          <div class="col-md-6">
            <section class="shadow-card text-left">
              <label>Imagen secundaria</label>
              <img :src="item.open_graph.url" alt="" class="img-fluid" />
            </section>
          </div>
        </div>

        <section>
          <label>Galería</label>
          <div class="row mt-3">
            <div
              class="col-md-4"
              v-for="(item, index) in item.galeria"
              :key="index"
            >
              <img :src="item.url" alt="" class="img-fluid" />
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <router-link
          :to="{ name: 'ProductsUpdate', params: { slug: item.slug } }"
          class="btn btn-info py-2 px-5"
        >
          Editar
          <i class="fas fa-edit ml-1"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductStatus, getProductFavoritesStatus } from "@/utils/static-values.js";

export default {
  props: {
    item: Object,
  },
  methods: {
    getStatusValue(key) {
      let keyTransform = key ? keyTransform = 1 : keyTransform = 2
      return getProductStatus(keyTransform);
    },
    getFavoritesStatus(key) {
      let keyTransform = key ? keyTransform = 1 : keyTransform = 2
      return getProductFavoritesStatus(keyTransform)
    }
  },
};
</script>

<style lang="scss" scoped>
#modal-details {
  .title {
    font-size: 1rem;
    max-width: 90%;
  }
}
</style>