<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div
      class="
        main-content-header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h1 class="card-title">Bancos</h1>

      <router-link
        :to="{ name: 'BanksCreate' }"
        type="button"
        class="btn btn-info"
      >
        Registrar banco
        <i class="fas fa-plus ml-2"></i>
      </router-link>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="table-options">
        <button
          type="button"
          class="btn btn-link text-dark text-uppercase font-weight-bold"
          @click="$bvModal.show('modal-filters')"
        >
          <i class="fas fa-filter"></i>
          Filtrar
        </button>

        <form action="" class="form-search mt-3 mt-md-0">
          <div
            class="
              form-group
              mb-0
              d-flex
              justify-content-end
              d-flex
              align-items-center
              h-100
            "
          >
            <input
              type="search"
              placeholder="Buscar por nombre o número de cuenta"
              class="form-control search-field"
              v-model="query"
            />
          </div>
        </form>
      </div>

      <div class="table-container">
        <table class="main-table" width="100%">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <p class="text-left">Nombre</p>
              </th>
              <th scope="col">Número de cuenta</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in searchItems" :key="item.id">
              <td class="cell">
                <img
                  :src="item.imagen.url"
                  :alt="item.titulo"
                  class="table-img"
                  v-if="item.imagen"
                />
              </td>
              <td class="cell text">
                <p class="table-name">
                  {{ item.titulo }}
                </p>
              </td>
              <td class="cell text-center">
                {{ item.numero }}
              </td>
              <td class="cell text-center">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="custom-dropdown"
                  no-caret
                  right
                  :no-flip="true"
                  menu-class="dropdown-options"
                  @show="opacity = true"
                  @hide="opacity = false"
                >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'BanksEdit',
                        params: { id: item.id },
                      })
                    "
                    >Editar</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click.prevent="deleteItem(item.id, index)"
                    >Eliminar</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="pagination-container d-flex justify-content-center mt-5">
        <b-pagination
          v-model="page"
          :total-rows="nroTotalItems"
          :per-page="number_paginate"
          @change="paginate($event)"
        ></b-pagination>
      </div> -->

      <ButtonToTop elementId="main-container" />
    </section>

    <b-modal id="modal-filters" title="Filtrar" hide-footer centered>
      <form @submit.prevent="filter()">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nro-items">Mostrar filas</label>
              <select
                id="nro-items"
                class="form-control select-options"
                v-model="number_paginate"
              >
                <option :value="item" v-for="item in paginateItems" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <section class="text-right mt-3 px-4">
          <a
            href=""
            class="text-success mr-4"
            @click="clear()"
            v-if="number_paginate != 5"
            >Limpiar filtros</a
          >

          <input type="submit" class="btn btn-primary px-4" value="Filtrar" />
        </section>
      </form>
    </b-modal>
  </section>
</template>

<script>
import { PAGINATE_ITEMS } from "@/utils/static-values.js";

import GetBancos from "../graphql/queries/GetBancos.gql";
import DeleteBancos from "../graphql/mutations/DeleteBancos.gql";

import { Loading, ButtonToTop } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      page: 1,
      number_paginate: 50,
      nroTotalItems: null,
      loading: true,
      paginateItems: PAGINATE_ITEMS,
      query: "",
    };
  },
  mounted() {
    this.init();
  },
  components: {
    Loading,
    ButtonToTop,
  },
  methods: {
    init() {
      this.getItems();
    },
    async getItems() {
      let response = await this.$apollo.query({
        query: GetBancos,
        variables: {
          number_paginate: this.number_paginate,
          page: this.page,
        },
        fetchPolicy: "no-cache",
      });

      this.nroTotalItems = response.data.GetBancos.NroItems;
      this.items = response.data.GetBancos.data;

      this.loading = false;
    },
    paginate(event) {
      this.page = event;

      this.getItems();

      const posts = document.getElementById("main-container");

      this.$smoothScroll({
        scrollTo: posts,
        duration: 1000,
        offset: -100,
        updateHistory: false,
      });
    },
    changeNroItems() {
      this.page = 1;
      this.getItems();
    },
    filter() {
      this.getItems();

      this.$bvModal.hide("modal-filters");
    },
    clear() {
      this.number_paginate = 5;

      this.$bvModal.hide("modal-filters");
    },
    deleteItem(id, index) {
      this.$swal({
        title: "Eliminar banco",
        text: "El elemento se eliminará de forma permanente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$apollo.mutate({
            mutation: DeleteBancos,
            variables: {
              input: {
                id,
              },
            },
          });

          this.items.splice(index, 1);

          this.$toast.success("Banco eliminado");
        }
      });
    },
  },
  computed: {
    searchItems: function () {
      if (this.query) {
        let queryStandar = this.query.toLowerCase();

        return this.items.filter((item) =>
          item.titulo.toLowerCase().includes(queryStandar) ||
          item.numero.includes(this.query)
        );
      } else {
        return this.items;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 200px;
    }
  }
}
</style>