import Vue from 'vue'
import Vuex from 'vuex'

import { products } from './modules/products.js'
import { orders } from './modules/orders.js'
import { auth } from './modules/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    products,
    orders,
    auth
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
