<template>
  <section class="main-layout" :class="{ 'main-layout--active': showSidebar }">
    <aside class="main-aside shadow-card">
      <MainNav :showSidebar="showSidebar" />
    </aside>

    <main class="pt-lg-3 pr-3">
      <HeaderApp @toggleSidebar="showSidebar = !showSidebar" :sidebar-status="showSidebar" />

      <section class="main-content shadow-card mt-3 px-3 d-flex flex-column justify-content-between">
        <slot name="main"></slot>

        <Footer />
      </section>
    </main>
  </section>
</template>

<script>
import HeaderApp from '@/components/global-app/HeaderApp'
import MainNav from '@/components/global-app/MainNav'
import Footer from '@/components/global-app/Footer'

export default {
    data() {
    return {
      showSidebar: false
    }
  },
  mounted() {
    this.calcResize()
  },
  components: {
    HeaderApp,
    MainNav,
    Footer
  },
  methods: {
    calcResize() {
      screen.width < 1024 ? this.showSidebar = false : this.showSidebar = true

      window.addEventListener('resize', () => {
        screen.width < 1024 ? this.showSidebar = false : this.showSidebar = true
      })
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main-layout {
  height: 100vh;
  position: relative;

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
  }

  .main-aside {
    width: 80vw;
    height: 100%;
    background-color: white;
    margin-top: .7rem;
    position: absolute;
    top: $header-height;
    left: -100%;
    z-index: 20;
    transition: left $sidebar-animation-duration;

    @media screen and (min-width: 992px) {
      max-width: $sidebar-width;
      margin-top: 0;
      top: 0;
    }
  }

  main {
    @media screen and (min-width: 992px) {
      grid-column: 1 / 3;
    }

    .main-content {
      min-height: 85vh;
    }
  }

  &--active {
    .main-aside {
      max-width: $sidebar-width;
      position: absolute;
      left: 0;

      @media screen and (min-width: 992px) {
        position: relative;
        top: 0;
      }
    }

    main {
      grid-column: 2 / 3;
    }
  }
}

header {
  display: flex;
  align-items: center;
}
</style>