<template>
  <b-jumbotron class="welcome-admin py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="title font-weight-bold">Bienvenido {{ currentUser.name }}! 🎉</h1>
          <p>
            Desde el panel de administración podrás acceder, agregar, modificar
            y eliminar información referente a tu tienda.
          </p>

          <p>
            Recuerda que si tienes algún problema puedes solicitar soporte
            escribiéndonos a <a href="mailto:soporte@softaki.com">soporte@softaki.com</a>
          </p>

          <p class="font-weight-bold">
            Estamos felices de ser tus aliados en el crecimiento de tu negocio.
          </p>
        </div>

        <div class="col-md-4 d-flex justify-content-center align-items-center">
          <img
            src="https://ouch-cdn2.icons8.com/bryeN5ra9uQXknNVtbxoSZjJFMRCmJWzSGtgrKxyghw/rs:fit:256:144/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMjUy/LzBmM2RjOTJhLTlm/M2YtNGZhYi1iM2Q3/LWI5ODczNDA2YTAx/My5zdmc.png"
            alt="Bienvenido"
          />
        </div>
      </div>
    </div>
  </b-jumbotron>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style lang="scss">
.welcome-admin {
  .title {
    font-size: 1.3em !important;
  }
}
</style>