<template>
  <div id="app">
    <MainLayout v-if="currentUser && $route.name != 'Login'">
      <template slot="main">
        <router-view />
      </template>
    </MainLayout>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { token_name } from '@/utils/static-values'

import MainLayout from './components/layouts/MainLayout.vue'

import GetProductos from "@/modules/products/graphql/queries/GetProductos.gql";
import GetAllPedidos from "@/modules/orders/graphql/queries/GetAllPedidos.gql";

export default {
  data() {
    return {
    }
  },
  mounted() {
    this.setItems()

    this.getUserData()
  },
  components: {
    MainLayout
  },
  methods: {
    async setItems() {
      let responseProducts = await this.getItems(GetProductos)
      this.$store.commit('products/SET_PRODUCTS', responseProducts.data.GetProductos.data)
      this.$store.commit('products/SET_TOTAL_PRODUCTS', responseProducts.data.GetProductos.NroItems)

      let responseOrders = await this.getItems(GetAllPedidos)
      this.$store.commit('orders/SET_ORDERS', responseOrders.data.GetPedidos.data)
      this.$store.commit('orders/SET_TOTAL_ORDERS', responseOrders.data.GetPedidos.NroItems)

      this.loading = false;
    },
    async getItems(query) {
      return await this.$apollo.query({
        query,
        variables: {
          number_paginate: 100,
          page: 1,
          estado: "",
        },
      });
    },
    getUserData() {
      const userData = this.$cookies.get(token_name()) || null

      this.$store.commit('auth/SET_USER', userData)
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  }
}
</script>


<style lang="scss">
</style>
