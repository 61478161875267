export const products = {
    namespaced: true,
    state: () => ({
        products: [],
        nroTotalProducts: 0
    }),
    mutations: {
        SET_PRODUCTS(state, data) {
            state.products = data
        },
        SET_TOTAL_PRODUCTS(state, value) {
            state.nroTotalProducts = value
        } 
    },
    actions: {
    },
    getters: {
        getProducts(state) {
            return state.products
        },
        getTotalProducts(state) {
            return state.nroTotalProducts
        }
    }
}