<template>
  <section
    class="products-list main-content animate__animated animate__fadeIn animate__faster"
  >
    <div
      class="main-content-header d-flex justify-content-between align-items-center"
    >
      <h1 class="card-title">Vendedores</h1>

      <router-link
        :to="{ name: 'VendorsCreate' }"
        type="button"
        class="btn btn-info"
      >
        Registrar vendedor
        <i class="fas fa-plus ml-2"></i>
      </router-link>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-4 position-relative" v-else>
      <div class="table-options">
        <!-- <button
          type="button"
          class="btn btn-link text-dark text-uppercase font-weight-bold"
          @click="$bvModal.show('modal-filters')"
        >
          <i class="fas fa-filter"></i>
          Filtrar
        </button> -->

        <form class="form-search my-0 py-0 ml-auto">
          <div
            class="form-group mb-0 d-flex justify-content-end d-flex align-items-center h-100"
          >
            <input
              type="search"
              placeholder="Buscar por nombres o apellidos"
              class="form-control search-field"
              v-model="query"
            />
          </div>
        </form>
      </div>

      <div class="table-container">
        <table class="main-table" width="100%">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <p class="text-left pl-2">Nombres</p>
              </th>
              <th scope="col">
                <p class="text-left pl-2">Apellidos</p>
              </th>
              <th scope="col">Estado</th>
              <th scope="col">Celular <img src="@/assets/images/icons/whatsapp.png" alt="" class="mb-1"></th>
              <th scope="col">
                <p class="text-left">Acciones</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in searchItems" :key="item.id">
              <td class="cell">
                <img :src="item.foto.url" alt="" class="table-img">
              </td>
              <td class="cell text">
                <p>
                  {{ item.nombre }}
                </p>
              </td>
              <td class="cell text">
                <p>
                  {{ item.apellido }}
                </p>
              </td>
              <td class="cell text text-uppercase text-center">
                <b-form-checkbox
                  v-model="items[index].estado"
                  name="check-button"
                  switch
                  @change="updateStatus(item)"
                  class="ml-2"
                >
                </b-form-checkbox>

                <span class="small">{{
                  item.estado ? "Visible" : "Oculto"
                }}</span>
              </td>
              <td class="cell text text-center">
                <a :href="item.link" target="_blank" class="text-dark"
                  >Enlace de contacto
                  <span class="text-success"
                    ><i class="fas fa-external-link-alt"></i
                  ></span>
                </a>
              </td>
              <td class="cell">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none"
                  class="custom-dropdown"
                  no-caret
                  right
                  :no-flip="true"
                  menu-class="dropdown-options"
                  @show="opacity = true"
                  @hide="opacity = false"
                >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'VendorsUpdate',
                        params: { id: item.id },
                      })
                    "
                    >Editar</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="#"
                    @click.prevent="deleteItem(item.id, index)"
                    >Eliminar</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ButtonToTop elementId="main-container" />
    </section>

    <b-modal id="modal-filters" title="Filtrar" hide-footer centered>
      <form @submit.prevent="filter()">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nro-items">Mostrar filas</label>
              <select
                id="nro-items"
                class="form-control select-options"
                v-model="number_paginate"
              >
                <option :value="item" v-for="item in paginateItems" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="products-status">Estado de producto</label>
              <select
                id="products-status"
                class="form-control select-options"
                v-model="status"
              >
                <option value="">Todos</option>
                <option value="1">Activos</option>
                <option value="0">Desactivados</option>
              </select>
            </div>
          </div>
        </div>

        <section class="text-right mt-3 px-4">
          <a
            href=""
            class="text-success mr-4"
            @click="clear()"
            v-if="number_paginate != 5 || status != ''"
            >Limpiar filtros</a
          >

          <input type="submit" class="btn btn-primary px-4" value="Filtrar" />
        </section>
      </form>
    </b-modal>
  </section>
</template>

<script>
import {
  PAGINATE_ITEMS,
  priceFormat,
} from "@/utils/static-values.js";

import GetAllVendedoras from "../graphql/queries/GetAllVendedoras.gql";
import UpdateVendedora from "../graphql/mutations/UpdateVendedora.gql"
import DeleteVendedora from "../graphql/mutations/DeleteVendedora.gql";

import { Loading, ButtonToTop } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      page: 1,
      number_paginate: 10,
      nroTotalItems: null,
      status: "",
      loading: true,
      paginateItems: PAGINATE_ITEMS,
      selectedItem: {},
      query: "",
    };
  },
  watch: {
    query: function (val) {
      if (!val.length) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Loading,
    ButtonToTop
  },
  methods: {
    init() {
      this.getItems();
    },
    async getItems() {
      let response = await this.$apollo.query({
        query: GetAllVendedoras,
        variables: {
          number_paginate: this.number_paginate,
          page: this.page,
          estado: this.status,
        },
        fetchPolicy: "no-cache",
      });

      this.items = response.data.GetAllVendedoras;

      this.items.forEach((item) => {
        item.estado == 0 ? (item.estado = false) : (item.estado = true);
      });

      this.loading = false;
    },
    paginate(event) {
      this.page = event;

      this.getItems();

      const posts = document.getElementById("main-container");

      this.$smoothScroll({
        scrollTo: posts,
        duration: 1000,
        offset: -100,
        updateHistory: false,
      });
    },
    changeNroItems() {
      this.page = 1;
      this.getItems();
    },
    filter() {
      this.getItems();

      this.$bvModal.hide("modal-filters");
    },
    clear() {
      this.number_paginate = 5;
      this.status = "";

      this.$bvModal.hide("modal-filters");
    },
    setPrice(price) {
      return priceFormat(price);
    },
    deleteItem(id, index) {
      this.$swal({
        title: "Eliminar vendedor",
        text: "El vendedor se eliminará de forma permanente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$apollo.mutate({
            mutation: DeleteVendedora,
            variables: {
              input: {
                id,
              },
            },
          });

          this.items.splice(index, 1);

          this.$toast.success("Vendedor eliminado");
        }
      });
    },
    showDetails(item) {
      this.selectedItem = item;

      this.$bvModal.show("modal-details");
    },
    async updateStatus(item) {
      let status = item.estado ? true : false;

      let input = {
        ...item,
        foto: item.foto.id,
        id: parseInt(item.id),
        estado: status
      };

      await this.$apollo.mutate({
        mutation: UpdateVendedora,
        variables: {
          input,
        },
      });

      this.$toast.success("Estado actualizado");
    }
  },
  computed: {
    searchItems: function () {
      if (this.query) {
        let queryStandar = this.query.toLowerCase();

        return this.items.filter((item) =>
          item.nombre.toLowerCase().includes(queryStandar) ||
          item.apellido.includes(this.query)
        );
      } else {
        return this.items;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 280px;
    }
  }
}
</style>
