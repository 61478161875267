<template>
  <section
    class="
      banners-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex justify-content-between align-items-center">
      <h1 class="card-title">Carrusel principal</h1>

      <router-link :to="{name: 'BannersCreate'}" type="button" class="btn btn-info">
        Registrar slide
        <i class="fas fa-plus ml-2"></i>
      </router-link>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div>
        <div class="row">
          <div class="col-md-6 mb-3" v-for="(item, index) in items" :key="item.id">
            <div class="card shadow-card">
              <img :src="item.banner.url" class="banner-image" :alt="item.id" />
              <div class="card-body pt-2">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="item-name mt-1 mb-0">{{ item.nombre }}</h5>
                  <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      class="custom-dropdown"
                      no-caret
                      right
                      :no-flip="true"
                      menu-class="dropdown-options"
                      @show="opacity = true"
                      @hide="opacity = false"
                    >
                      <template #button-content>
                        <i class="fas fa-ellipsis-v"></i>
                      </template>
                      <b-dropdown-item href="#" @click.prevent="$router.push({name: 'BannersUpdate', params: {id: item.id}})">Editar</b-dropdown-item>
                      <b-dropdown-item href="#" @click.prevent="deleteItem(item.id, index)">Eliminar</b-dropdown-item>
                    </b-dropdown>
                </div>


                <div class="d-flex justify-content-between align-items-center mt-2">
                  <p class="my-0">
                    <span class="text-muted">Tipo:</span>
                    {{ setTypeUrl(item.tipo_link).value }}
                  </p>

                  <a :href="`${appConfig.tiendaUrl}${item.link}`" target="_blank" class="banner-link mr-md-3">URL a redirigir</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ButtonToTop elementId="main-container" />
    </section>
  </section>
</template>

<script>
import { appConfig } from '../../../../app-config.js'

import { getUrlBannerType } from "@/utils/static-values.js";

import GetSliders from "../graphql/queries/GetSliders.gql";
import DeleteSlider from "../graphql/mutations/DeleteSlider.gql";

import { Loading, ButtonToTop } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      appConfig,
      items: [],
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  components: {
    Loading,
    ButtonToTop,
  },
  methods: {
    init() {
      this.getItems();
    },
    async getItems() {
      let response = await this.$apollo.query({
        query: GetSliders,
        fetchPolicy: 'no-cache'
      });

      this.items = response.data.GetSliders;

      this.loading = false;
    },
    setTypeUrl(key) {
      return getUrlBannerType(key);
    },
    deleteItem(id, index) {
      this.$swal({
        title: "Eliminar banner",
        text: "El elemento se eliminará de forma permanente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$apollo.mutate({
            mutation: DeleteSlider,
            variables: {
              input: {
                id
              },
            },
          });

          this.items.splice(index, 1)

          this.$toast.success("Slide eliminado")
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.banners-list {
  .banner-image {
    max-width: 100%;
  }

  .item-name {
    font-size: 1rem;
  }

  .banner-link {
    font-size: .9rem;
  }
}
</style>