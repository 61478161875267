import FinancesView from "../FinancesView.vue";
import BanksList from "../pages/BanksList.vue";
import BanksForm from "../pages/BanksForm.vue";
import MobileCashList from "../pages/MobileCashList.vue";
import MobileCashForm from "../pages/MobileCashForm.vue";

const routes = [
  {
    path: '/finanzas',
    component: FinancesView,
    children: [
      {
        path: '/bancos',
        name: 'BanksList',
        component: BanksList,
        meta: {
          auth: true
        }
      },
      {
        path: '/bancos/edit/:id',
        name: 'BanksEdit',
        component: BanksForm,
        meta: {
          auth: true
        }
      },
      {
        path: '/bancos/create',
        name: 'BanksCreate',
        component: BanksForm,
        meta: {
          auth: true
        }
      },
      {
        path: '/efectivo-movil',
        name: 'MobileCashList',
        component: MobileCashList,
        meta: {
          auth: true
        }
      },
      {
        path: '/efectivo-movil/edit/:id',
        name: 'MobileCashEdit',
        component: MobileCashForm,
        meta: {
          auth: true
        }
      },
      {
        path: '/efectivo-movil/create',
        name: 'MobileCashCreate',
        component: MobileCashForm,
        meta: {
          auth: true
        }
      }
    ]
  }
]

export default routes
