import dayjs from 'dayjs'

export const orders = {
    namespaced: true,
    state: {
        orders: [],
        nroTotalOrders: 0
    },
    mutations: {
        SET_ORDERS(state, data) {
            state.orders = data
        },
        SET_TOTAL_ORDERS(state, value) {
            state.nroTotalOrders = value
        }
    },
    getters: {
        getOrders(state) {
            return state.orders
        },
        getTotalOrders(state) {
            return state.nroTotalOrders
        },
        getTotalDailyOrders(state) {
            let today = dayjs().format('DD-MM-YYYY')
            return state.orders.filter(item => dayjs(item.fechaPedido).format('DD-MM-YYYY') === today).length
        }
    }
}