<template>
  <div class="dashboard-resume container">
    <div class="row justify-content-center">
      <div class="col-6 col-md-4">
        <div
          class="card shadow-card pointer"
        >
          <div
            class="card-body d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/icons/productos-registrados.png"
              alt="Productos registrados"
            />

            <div class="py-2 px-1 px-md-4 text-center text-md-left">
              <label class="small">
                <a :href="appConfig.tiendaUrl" target="_blank" class="font-weight-bold text-dark">
                  Visitar
                  tienda
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-4">
        <div
          class="card shadow-card pointer"
          @click="$router.push({ name: 'ProductsList' })"
        >
          <div
            class="card-body d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/icons/productos-registrados.png"
              alt="Productos registrados"
            />

            <div class="py-2 px-1 px-md-4 text-center text-md-left">
              <label class="small">
                Productos <br />
                registrados
              </label>
              <span class="font-weight-bold d-inline-block ml-3">{{ getTotalProducts }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-6 col-md-3">
        <div class="card shadow-card">
          <div
            class="
              card-body
              d-flex
              flex-column flex-md-row
              justify-content-center
              align-items-center
            "
          >
            <img
              src="@/assets/images/icons/clientes-registrados.png"
              alt="Productos registrados"
            />

            <div class="py-2 px-1 px-md-4 text-center text-md-left">
              <h3 class="small">
                Vistas <br />
                diarias
              </h3>
              <span class="font-weight-bold">150</span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-6 col-md-3">
        <div class="card shadow-card">
          <div
            class="card-body d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/icons/ordenes.png"
              alt="Productos registrados"
            />

            <div class="py-2 px-1 px-md-4 text-center text-md-left">
              <h3 class="small">
                Ventas <br />
                HOY
              </h3>
              <span class="font-weight-bold">{{ getTotalDailyOrders }}</span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-6 col-md-3">
        <div class="card shadow-card">
          <div
            class="
              card-body
              d-flex
              flex-column flex-md-row
              justify-content-center
              align-items-center
            "
          >
            <img
              src="@/assets/images/icons/ordenes.png"
              alt="Productos registrados"
            />

            <div class="py-2 px-1 px-md-4 text-center text-md-left">
              <h3 class="small">
                Total <br />
                pedidos
              </h3>
              <span class="font-weight-bold">{{ getTotalOrders }}</span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { appConfig } from '../../../../app-config.js'

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      appConfig
    }
  },
  computed: {
    ...mapGetters({
      getTotalProducts: "products/getTotalProducts",
      getTotalOrders: "orders/getTotalOrders",
      getTotalDailyOrders: "orders/getTotalDailyOrders",
    }),
  },
};
</script>

<style lang="scss">
.dashboard-resume {
  .card {
    height: 120px;
  }
}
</style>
