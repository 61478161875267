<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">
        {{ itemId ? "Actualizar" : "Registrar" }} banner
      </h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <b-form @submit.prevent="submit">
              <b-form-group id="nombre" label="Nombre" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.nombre"
                  type="text"
                  placeholder="Ingresar nombre identificador"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.nombre.$error && !$v.item.nombre.required"
                />
              </b-form-group>

              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="linkType">Tipo de enlace a redirigir</label>
                    <select
                      id="linkType"
                      class="form-control"
                      v-model="item.tipo_link"
                    >
                      <option
                        :value="item.value"
                        v-for="(item, index) in linkTypeBanner"
                        :key="index"
                      >
                        {{ item.text }}
                      </option>
                    </select>

                    <FormError
                      text="El tipo de enlace es requerido"
                      :show="
                        $v.item.tipo_link.$error && !$v.item.tipo_link.required
                      "
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="link" v-if="item.tipo_link != null"
                      >Enlace a redirigir</label
                    >
                    <b-input-group
                      id="link"
                      :prepend="
                        item.tipo_link == 0 ? '' : appConfig.tiendaUrl
                      "
                      v-if="item.tipo_link != null"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="item.link"
                        type="text"
                        placeholder="Ingresar enlace a redireccionar"
                      ></b-form-input>
                    </b-input-group>

                    <FormError
                      text="El enlace es requerido"
                      :show="$v.item.link.$error && !$v.item.link.required"
                      v-if="item.tipo_link != null"
                    />
                  </div>
                </div>
              </div>

              <section class="mt-4">
                <div class="d-flex justify-content-center" v-if="item.banner">
                  <img
                    :src="item.banner.url"
                    :alt="item.banner.nombre"
                    class="preview-image border"
                  />
                </div>

                <div class="text-right text-center mt-3" v-if="itemId">
                  <a href="" @click.prevent="showModalImages()"
                    >Actualizar imagen</a
                  >
                </div>

                <div class="select-image text-center mt-3" v-else>
                  <a
                    href=""
                    class="btn btn-outline-info"
                    @click.prevent="$bvModal.show('modal-images')"
                    >Asignar imagen</a
                  >
                </div>

                <FormError
                  text="La imagen es requerida"
                  :show="$v.item.banner.$error && !$v.item.banner.required"
                  class="text-center"
                />
              </section>

              <div
                class="
                  form-group
                  d-flex
                  justify-content-between
                  align-items-center
                  text-center
                  mt-5
                "
              >
                <a href="" @click.prevent="$router.back()" class="text-danger">
                  <i class="fas fa-chevron-left"></i>
                  Cancelar
                </a>

                <button
                  type="submit"
                  class="btn btn-success px-4 ml-3"
                  :disabled="loadingAction"
                >
                  {{ itemId ? "Actualizar" : "Crear" }}
                </button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <ImageManagement :oldImages="oldImages" @selected="selectedImage($event)" />
  </section>
</template>

<script>
import { appConfig } from '../../../../app-config.js'

import GetSliderID from "../graphql/queries/GetSliderID.gql";
import CreateSlider from "../graphql/mutations/CreateSlider.gql";
import UpdateSlider from "../graphql/mutations/UpdateSlider.gql";

import { LINK_TYPE_BANNER } from "@/utils/static-values.js";

import { Loading, ImageManagement, FormError } from "@/components";

import { required } from "vuelidate/lib/validators";

export default {
  name: "ProductsList",
  data() {
    return {
      appConfig,
      items: [],
      loading: true,
      itemId: null,
      item: {
        nombre: null,
        banner: null,
        tipo_link: null,
        link: null,
      },
      linkTypeBanner: LINK_TYPE_BANNER,
      loadingAction: false,
      oldImages: []
    };
  },
  mounted() {
    this.init();
  },
  validations: {
    item: {
      nombre: {
        required,
      },
      tipo_link: {
        required,
      },
      banner: {
        required,
      },
      link: {
        required,
      },
    },
  },
  components: {
    Loading,
    ImageManagement,
    FormError,
  },
  methods: {
    init() {
      this.itemId = this.$route.params.id;

      if (this.itemId) {
        this.getItemId();
      } else {
        this.loading = false;
      }
    },
    async getItemId() {
      let response = await this.$apollo.query({
        query: GetSliderID,
        variables: {
          id: this.itemId,
        },
      });

      let data = response.data.GetSliderID;

      if (data) {
        this.item = {
          ...data,
        };
      }

      this.loading = false;
    },
    showModalImages() {
      this.oldImages = [this.item.banner]
      this.$bvModal.show('modal-images')
    },
    selectedImage(event) {
      this.item.banner = event[0];
      this.$bvModal.hide("modal-images");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.loadingAction = true;

      let input = {
        ...this.item,
      };

      input.banner = input.banner.id;

      let mutation = this.itemId ? UpdateSlider : CreateSlider;

      await this.$apollo.mutate({
        mutation,
        variables: {
          input,
        },
      });

      this.loadingAction = false;
      this.$router.push({ name: "BannersList" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.preview-image {
  max-width: 100%;
  max-height: 220px;
}
</style>