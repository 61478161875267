<template>
  <button type="button" class="button-to-top" @click="toTop()">
    <i class="fas fa-arrow-circle-up"></i>
  </button>
</template>

<script>
export default {
  props: {
    elementId: String,
  },
  methods: {
    toTop() {
      const container = document.getElementById(this.elementId);

      this.$smoothScroll({
        scrollTo: container,
        duration: 1000,
        offset: 0,
        updateHistory: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.button-to-top {
  width: 40px;
  height: 40px;
  background-color: rgba($primary, 0.9);
  color: white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  bottom: 2rem;
  right: 2.5rem;

  &:hover {
      background-color: $primary;
  }
}
</style>