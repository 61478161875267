<template>
  <div class="footer bg-light py-2">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <p class="my-0">
            <span class="text-muted mr-1">
              <i class="fas fa-code"></i>
            </span>
            Por <a href="https://www.softaki.com" target="_blank">Softaki</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.footer {
  border-top: 1px solid rgba($secondary, 0.2);
}
</style>