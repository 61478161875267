<template>
  <section id="main-container" class="images main-content animate__animated animate__fadeIn animate__faster">
    <div class="main-content-header d-flex justify-content-between align-items-center">
      <div>
        <h1 class="card-title">Imágenes</h1>
      </div>

      <router-link :to="{name: 'ImagesUpload'}" type="button" class="btn btn-info">
        Registrar imagen
        <i class="fas fa-cloud-upload-alt ml-2"></i>
      </router-link>
    </div>

    <Loading v-if="loading" />

    <section class="container mt-5 px-1" v-else>
      <div class="row">
        <div
          class="col-6 col-md-3 mb-4"
          v-for="(item, index) in images"
          :key="index"
        >
          <div
            class="
              images-item
              d-flex
              justify-content-center
              align-items-center
              pointer
            "
            @click="setSelectedImage(item, index)"
          >
            <img :src="item.url" :alt="item.nombre" />
          </div>
        </div>
      </div>
    </section>

    <ButtonToTop elementId="main-container" />

    <!-- assuming Animate.css is included on the page -->
    <Transition
      name="custom-classes"
      enter-active-class="animate__animated animate__slideInRight animate__faster"
      leave-active-class="animate__animated animate__slideOutRight animate__faster"
    >
      <ImagesOptions
        :item="selectedItem"
        @show="showOptions = true"
        @hide="showOptions = false"
        @deleted="removeImage($event)"
        @updated="updateItem($event)"
        v-if="showOptions"
      />
    </Transition>
  </section>
</template>

<script>
import ImagesOptions from "../components/ImagesOptions";

import GetImagenes from "../graphql/queries/GetImagenes.gql";

import { Loading, ButtonToTop } from "@/components";

export default {
  name: "ImagesPage",
  data() {
    return {
      showOptions: false,
      images: [],
      loading: true,
      selectedItem: {}
    };
  },
  mounted() {
    this.load();
  },
  components: {
    ImagesOptions,
    Loading,
    ButtonToTop,
  },
  methods: {
    async load() {
      let response = await this.$apollo.query({ query: GetImagenes, variables: {estado: ''}, fetchPolicy: 'no-cache' });

      this.images = response.data.GetImagenes.reverse();

      this.loading = false;
    },
    setSelectedImage(item, index) {
      this.selectedItem.info = item
      this.selectedItem.index = index

      this.showOptions = !this.showOptions
    },
    removeImage(index) {
      this.images.splice(index, 1)

      this.showOptions = false
    },
    updateItem(data) {
      this.images[data.index] = {
        ...data.info
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.images {
  &-item {
    max-height: 180px;

    border: 2px solid $light;

    &:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
    }

    img {
      max-width: 100%;
      max-height: 120px;

      @media (min-width: 768px) {
        max-height: 175px;
      }
    }
  }
}
</style>