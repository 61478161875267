import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
// import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link';
import VueCookies from 'vue-cookies';

import { appConfig } from '../../app-config.js'
import { token_name } from '../utils/static-values.js'

Vue.use(VueApollo)

// HTTP connection to the API
const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: appConfig.graphql_endpoint
})

const authMiddleware = new ApolloLink((operation, forward) => {
    let userData = VueCookies.get(token_name())

    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: userData ? userData.api_token : '',
      }
    });
    
    return forward(operation);
})

// Cache implementation
const cache = new InMemoryCache({
    addTypename: false
})

// Create the apollo client
const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache
})

export default new VueApollo({
    defaultClient: apolloClient
})