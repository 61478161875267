<template>
  <section
    class="
      districts-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">Distritos</h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="d-flex justify-content-end pb-3">
            <form action="" class="form-search mt-3 mt-md-0">
              <div
                class="
                  form-group
                  mb-0
                  d-flex
                  justify-content-end
                  d-flex
                  align-items-center
                  h-100
                "
              >
                <input
                  type="search"
                  placeholder="Buscar por nombre o código"
                  class="form-control search-field"
                  v-model="query"
                />
              </div>
            </form>
          </div>

          <div class="table-container">
            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">
                    <p class="text-left">Nombre</p>
                  </th>
                  <th scope="col">Costo de envío</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in searchItems" :key="item.id">
                  <td class="cell text-center">
                    {{ item.DistCodi }}
                  </td>
                  <td class="cell">
                    {{ item.DistNom }}
                  </td>
                  <td class="cell text-center" :class="{'font-weight-bold': item.costo_envio}">
                    {{ item.costo_envio ? setPrice(item.costo_envio) : 'No asignado' }}
                  </td>
                  <td class="cell text-center">
                    <b-form-checkbox
                      v-model="items[index].estado"
                      name="check-button"
                      switch
                      @change="updateStatus(item)"
                    >
                      <b>{{ !item.estado ? "Oculto" : "Visible" }}</b>
                    </b-form-checkbox>
                  </td>
                  <td class="cell text-center">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      class="custom-dropdown"
                      no-caret
                      right
                      :no-flip="true"
                      menu-class="dropdown-options"
                      @show="opacity = true"
                      @hide="opacity = false"
                    >
                      <template #button-content>
                        <i class="fas fa-ellipsis-v"></i>
                      </template>
                      <b-dropdown-item href="#" @click.prevent="showCostShipping(item)"
                        >Asignar costo de envío</b-dropdown-item
                      >
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

      <ButtonToTop elementId="main-container" />
    </section>

    <b-modal
      id="modal-cost-shipping"
      :title="`Cambiar costo de envío del distrito de ${selectedItem.DistNom}`"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >

      <form @submit.prevent="updateCostShipping()">
        <div class="form-group">
          <label for="status">Indique el monto a cobrar</label>
          <input type="number" class="form-control" placeholder="Ingrese monto" v-model="selectedItem.costo_envio">
        </div>

        <div
          class="
            form-group
            d-flex
            justify-content-between
            align-items-center
            mt-5
          "
        >
          <a href="" @click.prevent="closeModalCostShipping()" class="text-danger">
            <i class="fas fa-chevron-left"></i>
            Cancelar
          </a>

          <input type="submit" value="Actualizar" class="btn btn-success" />
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import {
  PAGINATE_ITEMS,
  priceFormat
} from "@/utils/static-values.js";

import GetDistritos from "../graphql/queries/GetDistritos.gql";
import updateEstadoDistrito from '../graphql/mutations/updateEstadoDistrito.gql'
import createPrecioEnvioDistrito from '../graphql/mutations/createPrecioEnvioDistrito.gql'

import { Loading, ButtonToTop } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      page: 1,
      number_paginate: 5,
      nroTotalItems: null,
      status: "",
      loading: true,
      paginateItems: PAGINATE_ITEMS,
      selectedItem: {},
      query: '',
      loadingAction: false
    };
  },
  mounted() {
    this.init();
  },
  components: {
    Loading,
    ButtonToTop,
  },
  methods: {
    init() {
      this.getItems();
    },
    async getItems() {
      let provinceCode = 128;

      let response = await this.$apollo.query({
        query: GetDistritos,
        variables: {
          ProCode: provinceCode,
        },
        fetchPolicy: 'no-cache'
      });

      this.items = response.data.GetDistritos;

      this.items.forEach((item) => {
        if (item.estado == 0) {
          item.estado = false;
        } else {
          item.estado = true;
        }
      });

      this.loading = false;
    },
    async updateStatus(item) {
      let status = item.estado ? 1 : 0

      let input = {
        DistCodi: parseInt(item.DistCodi),
        estado: parseInt(status)
      }

      await this.$apollo.mutate({
        mutation: updateEstadoDistrito,
        variables: {
          input
        }
      })
      
      this.$toast.success('Estado actualizado')
    },
    showCostShipping(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));

      this.$bvModal.show("modal-cost-shipping")
    },
    setPrice(price) {
      return priceFormat(price);
    },
    closeModalCostShipping() {
      this.selectedItem = {}

      this.$bvModal.hide('modal-cost-shipping')
    },
    async updateCostShipping() {
      this.loadingAction = true

      await this.$apollo.mutate({
        mutation: createPrecioEnvioDistrito,
        variables: {
          input: {
            DistCodi: this.selectedItem.DistCodi,
            costo_envio: this.selectedItem.costo_envio
          }
        }
      })

      this.closeModalCostShipping()

      this.getItems()

      this.$toast.success("Costo de envío actualizado")
    }
  },
  computed: {
    searchItems: function () {
      if (this.query) {
        let queryStandar = this.query.toLowerCase();

        return this.items.filter((item) =>
          item.DistNom.toLowerCase().includes(queryStandar) ||
          item.DistCodi.includes(this.query)
        );
      } else {
        return this.items;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>