<template>
  <section
    class="products-list main-content animate__animated animate__fadeIn animate__faster"
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">
        {{ itemSlug ? "Actualizar" : "Registrar" }} vendedor
      </h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="row">
        <div class="col-md-8">
          <b-form @submit.prevent="onSubmit()">
            <div class="border-bottom pb-3">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group justify-content-between">
                    <label for="nombre">Nombres</label>
                    <b-form-input
                      id="nombre"
                      v-model="item.nombre"
                      type="text"
                      placeholder="Ingresar nombres"
                    ></b-form-input>

                    <FormError
                      text="Campo requerido"
                      :show="
                        $v.item.nombre.$error && !$v.item.nombre.required
                      "
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="apellido">Apellidos</label>
                    <b-form-input
                      id="apellido"
                      v-model="item.apellido"
                      type="text"
                      placeholder="Ingresar apellidos"
                    ></b-form-input>

                    <FormError
                      text="Campo requerido"
                      :show="
                        $v.item.apellido.$error &&
                        !$v.item.apellido.required
                      "
                    />
                  </div>
                </div>
              </div>

              <b-form-group id="link" label="Link para contacto" label-for="input-link">
                <b-form-input
                  id="input-link"
                  v-model="item.link"
                  type="text"
                  placeholder="Ingresar link"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.link.$error && !$v.item.link.required"
                />
              </b-form-group>
            </div>

            <div
              class="form-group d-flex justify-content-between align-items-center mt-3"
            >
              <a href="" @click.prevent="$router.back()" class="text-danger">
                <i class="fas fa-chevron-left"></i>
                Cancelar
              </a>

              <input
                type="submit"
                class="btn btn-success"
                :value="itemSlug ? 'Actualizar' : 'Registrar'"
              />
            </div>
          </b-form>
        </div>

        <div class="col-md-4">
          <div>
            <label class="label">Foto</label>

            <section
              class="
                dropzone-item
                d-flex
                flex-column
                justify-content-center
                align-items-center
                mt-2
                pb-2
              "
            >
              <img
                :src="item.foto.url"
                alt="Imagen destacada"
                class="img-fluid w-100 h-100"
                v-if="item.foto"
              />

              <a
                href=""
                class="text-info font-weight-bold"
                @click.prevent="showModalImages('foto')"
                >Seleccionar imagen</a
              >
            </section>

            <FormError
              text="La imagen destacada es requerida"
              :show="$v.item.foto.$error && !$v.item.foto.required"
            />
          </div>
        </div>
      </div>
    </section>

    <ImageManagement
      :multiple="uploadMultiple"
      :oldImages="oldImages"
      @selected="selectedImage($event)"
    />
  </section>
</template>

<script>
import GetVendedorasID from "../graphql/queries/GetVendedorasID.gql";

import CreateVendedora from "../graphql/mutations/CreateVendedora.gql";
import UpdateVendedora from "../graphql/mutations/UpdateVendedora.gql";

import { required } from "vuelidate/lib/validators";

import { ImageManagement, FormError } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      categories: [{ id: null, nombre: "Seleccione una categoría" }],
      itemSlug: null,
      item: {
        nombre: null,
        apellido: null,
        link: null,
        foto: null
      },
      loading: false,
      typeUploadImage: null,
      uploadMultiple: false,
      showOpenGraphImage: false,
      showGalleryImage: false,
      oldImages: [],
    };
  },
  mounted() {
    this.init();
  },
  components: {
    ImageManagement,
    FormError,
  },
  validations: {
    item: {
      nombre: {
        required,
      },
      apellido: {
        required
      },
      link: {
        required
      },
      foto: {
        required
      }
    },
  },
  methods: {
    init() {
      this.itemSlug = this.$route.params.id;

      if (this.itemSlug) {
        this.getitemSlug();
      } else {
        this.loading = false;
      }
    },
    async getitemSlug() {
      let response = await this.$apollo.query({
        query: GetVendedorasID,
        variables: {
          id: this.itemSlug,
        },
        fetchPolicy: "no-cache",
      });

      let data = response.data.GetVendedorasID;

      if (data) {
        this.item = {
          ...data,
        };
      }

      console.log('w')
      console.log(this.item)

      this.loading = false;
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      let mutation = this.itemSlug ? UpdateVendedora : CreateVendedora;

      this.item.foto = this.item.foto.id
      this.item.estado = true

      await this.$apollo.mutate({
        mutation,
        variables: {
          input: {
            ...this.item,
            id: this.item.id
          },
        },
      });

      this.itemSlug
        ? this.$toast.success("Datos actualizados")
        : this.$toast.success("Vendedor registrado");
      this.$router.push({ name: "VendorsList" });
    },
    showModalImages(type) {
      this.typeUploadImage = type;

      if (this.typeUploadImage === "galeria") {
        this.uploadMultiple = true;
        this.oldImages = [...this.item.galeria];
      } else if (this.typeUploadImage === "foto") {
        this.uploadMultiple = false;
        this.oldImages = [this.item.foto];
      } else if (this.typeUploadImage === "open_graph") {
        this.uploadMultiple = false;
        this.oldImages = [this.item.open_graph];
      }

      this.$bvModal.show("modal-images");
    },
    selectedImage(data) {
      if (this.typeUploadImage === "foto") {
        this.item.foto = data[0];
      }

      this.$bvModal.hide("modal-images");
    },
    removeImage(index) {
      this.item.galeria.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 280px;
    }
  }

  .dropzone-item {
    min-height: 200px;
    border: 1px dashed $info;
  }
}
</style>
