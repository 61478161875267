<template>
  <b-modal
    id="modal-images"
    title="Administrar imágenes"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
  >
    <div class="modal-container">
      <section v-if="sectionType === 'list'">
        <div class="row">
          <div
            class="
              col-6 col-md-2
              modal-item
              d-flex
              justify-content-center
              align-items-center
              mb-3
              px-md-0
            "
            v-for="(item, index) in images"
            :key="index"
          >
            <img
              :src="item.url"
              :alt="item.nombre"
              class="pointer"
              :class="{ 'active': item.selected }"
              @click="onClick(item, index)"
            />
          </div>
        </div>
      </section>

      <section v-else>Lista de imágenes</section>

      <div
        class="
          modal-options
          d-flex
          justify-content-center
          py-3
          bg-white
          shadow-card
        "
        v-if="selectedImages.length"
      >
        <button
          type="button"
          class="btn btn-success py-2 px-3"
          @click="selected()"
        >
          Seleccionar imagen
        </button>
        <button
          type="button"
          class="btn btn-danger py-2 px-3 ml-3"
          @click="close()"
        >
          Cancelar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import GetImagenes from "@/modules/images/graphql/queries/GetImagenes.gql";

export default {
  data() {
    return {
      sectionType: "list",
      loading: true,
      images: [],
      selectedImage: null,
      gallery: [],
      imagesToExport: []
    };
  },
  mounted() {
    this.loadImages();
  },
  props: {
    multiple: Boolean,
    oldImages: Array
  },
  watch: {
    oldImages: function(val) {
      this.images.forEach((item) => (item.selected = false))

      val.forEach((item) => {
        let index = this.images.findIndex(img => img.id == item.id)
        this.images[index].selected = true
      })
    }
  },
  methods: {
    async loadImages() {
      let response = await this.$apollo.query({
        query: GetImagenes,
        variables: {
          estado: ''
        },
        fetchPolicy: "no-cache",
      });

      this.images = response.data.GetImagenes.map(item => {
        return {
          ...item,
          selected: false
        }
      })

      this.loading = false;
    },
    onClick(item) {
      if(this.multiple) {
        item.selected ? item.selected = false : item.selected = true
      } else {
        this.images.forEach(image => image.selected = false)
        item.selected ? item.selected = false : item.selected = true
      }
    },
    selected() {
      this.$emit('selected', this.selectedImages)
      this.close()
    },
    close() {
      this.images.forEach((item) => (item.selected = false))
      this.$bvModal.hide('modal-images')
    }
  },
  computed: {
    selectedImages: function() {
      return this.images.filter(item => item.selected === true)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

#modal-images {
  .modal-container {
    max-height: 75vh;
    padding-bottom: 3rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .modal-item {
    img {
      max-width: 100%;
      max-height: 120px;
    }
  }

  .modal-options {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .active {
    border: 3px solid $success;
  }
}
</style>