<template>
  <aside
    class="options bg-transparent w-100 d-flex justify-content-end"
    @click="$emit('hide')"
  >
    <section
      class="options-content bg-light shadow-card position-relative"
      @click.stop="$emit('show')"
    >
      <div
        class="options-content-header bg-info text-white d-flex justify-content-between align-items-center px-3"
      >
        <h3 class="text-uppercase my-0">Información de imagen</h3>

        <button
          type="button"
          class="close-button text-white bg-transparent border-0"
          @click="$emit('hide')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="p-3">
        <div class="text-right">
          <span
            class="small text-muted d-inline-block mr-2"
            v-if="item.info.estado === 'Activado'"
            >Imagen destacada</span
          >
          <button
            type="button"
            class="lead bg-transparent border-0"
            :class="item.info.estado === 'Activado' ? 'text-warning' : 'text-muted'"
            @click="toggleStatus()"
          >
            <i class="fas fa-star"></i>
          </button>
        </div>

        <div class="text-center mt-3">
          <img :src="item.info.url" alt="" class="main-image" />
        </div>

        <form action="">
          <div class="form-group">
            <label for="description">Descripción</label>
            <input
              type="text"
              id="description"
              class="form-control"
              v-model="description"
              @input="showUpdateLink = true"
            />
          </div>
          <div class="form-group text-right" v-if="showUpdateLink">
            <a href="" class="text-blue" @click.prevent="update()">{{
              loading ? "Actualizando" : "Actualizar "
            }}</a>
          </div>

          <div class="text-right mt-5">
            <button
              type="button"
              class="btn btn-danger py-1 px-2"
              @click="showModalDelete()"
            >
              Eliminar imagen
            </button>
          </div>
        </form>

        <ModalDeleteImage
          :item="item"
          @close="modalDelete = false"
          @deleted="deletedImage($event)"
          v-if="modalDelete"
        />
      </div>
    </section>
  </aside>
</template>

<script>
import UpdateImage from "../graphql/mutations/UpdateImage.gql";

import ModalDeleteImage from "../components/ModalDeleteImage.vue";

export default {
  name: "ImagesOptions",
  data() {
    return {
      modalDelete: false,
      description: null,
      showUpdateLink: false,
      loading: false,
    };
  },
  mounted() {
    if (this.item) {
      this.description = this.item.info.nombre;
    }
  },
  props: {
    item: Object,
  },
  components: {
    ModalDeleteImage,
  },
  methods: {
    showModalDelete() {
      this.modalDelete = true;
    },
    deletedImage(index) {
      this.modalDelete = false;
      this.$emit("deleted", index);
    },
    async update() {
      if (!this.showUpdateLink) {
        return;
      }

      this.loading = true;

      await this.$apollo.mutate({
        mutation: UpdateImage,
        variables: {
          id: this.item.info.id,
          nombre: this.description,
          estado: this.item.info.estado,
        },
      });

      this.item.info.nombre = this.description;

      this.$emit("updated", this.item);

      this.loading = false;
      this.showUpdateLink = false;

      this.$toast.success("Imagen actualizada");
    },
    async toggleStatus() {
      console.log('w')
      await this.$apollo.mutate({
        mutation: UpdateImage,
        variables: {
          id: this.item.info.id,
          nombre: this.description,
          estado: this.item.info.estado === 'Activado' ? 'Desactivado' : 'Activado',
        },
      })

      this.item.info.estado = this.item.info.estado === 'Activado' ? 'Desactivado' : 'Activado'

      this.$toast.success("Estado actualizado");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.options {
  width: 100%;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;

  @media (min-width: 768px) {
    top: 100px;
  }

  &-content {
    width: 90%;
    height: 100%;

    @media (min-width: 768px) {
      width: 40%;
    }

    @media (min-width: 992px) {
      width: 30%;
    }

    .main-image {
      max-width: 50%;
    }
  }

  &-content-header {
    height: 50px;

    h3 {
      font-size: 0.9em;
    }
  }
}
</style>
