<template>
  <section
    class="products-list main-content animate__animated animate__fadeIn animate__faster"
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">
        {{ itemSlug ? "Actualizar" : "Registrar" }} producto
      </h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="row">
        <div class="col-md-8">
          <b-form @submit.prevent="onSubmit()">
            <div class="border-bottom pb-3">
              <div class="row">
                <div class="col-12">
                  <h2 class="small text-muted text-uppercase mb-3">
                    Información general
                  </h2>
                </div>
                <div class="col-md-6">
                  <div class="form-group justify-content-between">
                    <label for="linkType">Tipo de producto</label>
                    <select
                      id="linkType"
                      class="form-control"
                      v-model="item.favoritos"
                    >
                      <option
                        :value="item.value"
                        v-for="(item, index) in productStatus"
                        :key="index"
                      >
                        {{ item.text }}
                      </option>
                    </select>

                    <FormError
                      text="El tipo de producto es requerido"
                      :show="
                        $v.item.favoritos.$error && !$v.item.favoritos.required
                      "
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="linkType">Categoría</label>
                    <select
                      id="linkType"
                      class="form-control"
                      v-model="item.categoria_producto_id"
                    >
                      <option
                        :value="item.id"
                        v-for="(item, index) in categories"
                        :key="index"
                      >
                        {{ item.nombre }}
                      </option>
                    </select>

                    <FormError
                      text="La categoría es requerida"
                      :show="
                        $v.item.categoria_producto_id.$error &&
                        !$v.item.categoria_producto_id.required
                      "
                    />
                  </div>
                </div>
              </div>

              <b-form-group id="nombre" label="Nombre" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.nombre"
                  type="text"
                  placeholder="Ingresar nombre"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.nombre.$error && !$v.item.nombre.required"
                />
              </b-form-group>

              <b-form-group
                id="descripcionCorta"
                label="Descripción corta"
                label-for="descripcionCorta"
              >
                <!-- <b-form-textarea
                  id="descripcionCorta"
                  v-model="item.descripcionCorta"
                  placeholder="Resumen a mostrar"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea> -->

                <quill-editor
                  ref="myQuillEditor"
                  v-model="item.descripcionCorta"
                  :options="editorOption"
                />
              </b-form-group>

              <b-form-group
                id="descripcionLarga"
                label="Descripción completa"
                label-for="descripcionLarga"
              >
                <!-- <b-form-textarea
                  id="descripcionLarga"
                  v-model="item.descripcionLarga"
                  placeholder="Resumen a mostrar"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea> -->

                <quill-editor
                  ref="myQuillEditor"
                  v-model="item.descripcionLarga"
                  :options="editorFullOption"
                />
              </b-form-group>

              <b-form-group
                id="keywords"
                label="Palabras clave para SEO"
                label-for="keywords"
              >
                <b-form-tags
                  input-id="keywords"
                  v-model="item.keywords"
                ></b-form-tags>
              </b-form-group>
            </div>

            <!-- <section class="border-bottom pb-3">
              <div>
                <h2 class="small text-muted text-uppercase mt-4 mb-3">Stock</h2>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="stok_real"
                    label="Stock real"
                    label-for="stok_real"
                  >
                    <b-form-input
                      id="stok_real"
                      v-model="item.stok_real"
                      type="number"
                      step="1"
                      placeholder="Ingresar stock"
                    ></b-form-input>

                    <FormError
                      text="El stock real es requerido"
                      :show="
                        $v.item.stok_real.$error && !$v.item.stok_real.required
                      "
                    />
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="stok_minimo"
                    label="Stock mínimo"
                    label-for="stok_minimo"
                  >
                    <b-form-input
                      id="stok_minimo"
                      v-model="item.stok_minimo"
                      type="number"
                      step="1"
                      placeholder="Ingresar stock"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </section> -->

            <div>
              <h2 class="small text-muted text-uppercase mt-4 mb-3">Precios</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="precio_real"
                  label="Precio real"
                  label-for="precio_real"
                >
                  <b-form-input
                    id="precio_real"
                    v-model="item.precio_real"
                    type="number"
                    step=".1"
                    placeholder="Ingresar precio"
                  ></b-form-input>

                  <FormError
                    text="El precio real es requerido"
                    :show="
                      $v.item.precio_real.$error &&
                      !$v.item.precio_real.required
                    "
                  />
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group
                  id="precio_descuento"
                  label="Precio con descuento"
                  label-for="precio_descuento"
                >
                  <b-form-input
                    id="precio_descuento"
                    v-model="item.precio_descuento"
                    type="number"
                    step=".1"
                    placeholder="Ingresar precio"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div
              class="form-group d-flex justify-content-between align-items-center mt-3"
            >
              <a href="" @click.prevent="$router.back()" class="text-danger">
                <i class="fas fa-chevron-left"></i>
                Volver
              </a>

              <input
                type="submit"
                class="btn btn-success"
                :value="itemSlug ? 'Actualizar producto' : 'Registrar producto'"
              />
            </div>
          </b-form>
        </div>

        <div class="col-md-4">
          <div>
            <label class="label">Imagen destacada</label>

            <section
              class="dropzone-item d-flex flex-column justify-content-center align-items-center mt-2 pb-2"
            >
              <img
                :src="item.foto_real.url"
                alt="Imagen destacada"
                class="img-fluid w-100 h-100"
                v-if="item.foto_real"
              />

              <a
                href=""
                class="text-info font-weight-bold"
                @click.prevent="showModalImages('foto_real')"
                >Seleccionar imagen</a
              >
            </section>

            <FormError
              text="La imagen destacada es requerida"
              :show="$v.item.foto_real.$error && !$v.item.foto_real.required"
            />
          </div>

          <div class="mt-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="label">Imagen secundaria</label>

              <b-form-checkbox
                v-model="showOpenGraphImage"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>

            <section
              class="dropzone-item d-flex flex-column justify-content-center align-items-center mt-2 pb-2"
              v-if="showOpenGraphImage"
            >
              <img
                :src="item.open_graph.url"
                alt="Imagen secundaria"
                class="img-fluid w-100 h-100"
                v-if="item.open_graph"
              />

              <a
                href=""
                class="text-info font-weight-bold"
                @click.prevent="showModalImages('open_graph')"
                >Seleccionar imagen</a
              >
            </section>
          </div>

          <div class="mt-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="label">Galería</label>

              <b-form-checkbox
                v-model="showGalleryImage"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>

            <section
              class="dropzone-item d-flex justify-content-center align-items-center mt-2 py-3"
              v-if="showGalleryImage"
            >
              <div class="row px-3" v-if="item.galeria.length">
                <div
                  class="col-md-4 mb-3"
                  v-for="(image, index) in item.galeria"
                  :key="index"
                >
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="text-danger d-block border-0"
                      @click="removeImage(index)"
                    >
                      <i class="fas fa-minus-circle"></i>
                    </button>
                  </div>
                  <img
                    :src="image.url"
                    alt="Imagen destacada"
                    class="img-fluid w-100 h-100"
                  />
                </div>
              </div>

              <a
                href=""
                class="text-info"
                @click.prevent="showModalImages('galeria')"
                v-else
                >Seleccionar imágenes</a
              >
            </section>

            <div class="text-center">
              <a
                href=""
                class="text-info"
                @click.prevent="showModalImages('galeria')"
                v-if="item.galeria.length"
                >Seleccionar imágenes</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <ImageManagement
      :multiple="uploadMultiple"
      :oldImages="oldImages"
      @selected="selectedImage($event)"
    />
  </section>
</template>

<script>
import { PRODUCT_STATUS } from "@/utils/static-values.js";

import GetGetProductoSlug from "../graphql/queries/GetGetProductoSlug.gql";
import GetCategorias from "@/modules/products/graphql/queries/GetCategorias.gql";

import CreateProducto from "../graphql/mutations/CreateProducto.gql";
import UpdateProducto from "../graphql/mutations/UpdateProducto.gql";

import { required } from "vuelidate/lib/validators";

import { ImageManagement, FormError } from "@/components";

var toolbarOptions = ['bold', 'italic', 'underline', 'strike'];
var toolbarFullOptions = [[{ 'header': [1, 2, 3, 4, 5, 6, false] }, {'color': []}, {'font': []}, 'bold', 'italic', 'underline', 'strike', 'link', { 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }, 'clean']]

export default {
  name: "ProductsList",
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
      editorFullOption: {
        modules: {
          toolbar: toolbarFullOptions,
        },
      },
      productStatus: PRODUCT_STATUS,
      categories: [{ id: null, nombre: "Seleccione una categoría" }],
      itemSlug: null,
      item: {
        favoritos: 1,
        categoria_producto_id: null,
        nombre: null,
        descripcionCorta: null,
        descripcionLarga: null,
        keywords: [],
        stok_real: 100,
        stok_minimo: 100,
        precio_real: null,
        precio_descuento: null,
        foto_real: null,
        open_graph: null,
        galeria: [],
      },
      loading: false,
      typeUploadImage: null,
      uploadMultiple: false,
      showOpenGraphImage: false,
      showGalleryImage: false,
      oldImages: [],
    };
  },
  mounted() {
    this.init();
  },
  components: {
    ImageManagement,
    FormError,
  },
  validations: {
    item: {
      favoritos: {
        required,
      },
      categoria_producto_id: {
        required,
      },
      nombre: {
        required,
      },
      stok_real: {
        required,
      },
      precio_real: {
        required,
      },
      foto_real: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.getCategories();

      this.itemSlug = this.$route.params.slug;

      if (this.itemSlug) {
        this.getitemSlug();
      } else {
        this.loading = false;
      }
    },
    async getCategories() {
      let response = await this.$apollo.query({
        query: GetCategorias,
        variables: {
          estado: "",
        },
      });

      this.categories.push(...response.data.GetCategoria);

      this.loading = false;
    },
    async getitemSlug() {
      let response = await this.$apollo.query({
        query: GetGetProductoSlug,
        variables: {
          slug: this.itemSlug,
        },
        fetchPolicy: "no-cache",
      });

      let data = response.data.GetGetProductoSlug;

      if (data) {
        this.item = {
          ...data,
        };

        if (this.item.keywords) {
          this.item.keywords = this.item.keywords.split(",");
        }

        if (this.item.open_graph) {
          this.showOpenGraphImage = true;
        }

        if (this.item.galeria.length) {
          this.showGalleryImage = true;
        }
      }

      this.loading = false;
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      let foto_real = this.item.foto_real ? this.item.foto_real.id : null,
        open_graph = this.item.open_graph ? this.item.open_graph.id : null,
        galery = [],
        keywords = this.item.keywords.join();

      this.item.galeria.forEach((item) => {
        galery.push(item.id);
      });

      let mutation = this.itemSlug ? UpdateProducto : CreateProducto;

      if (this.itemSlug) {
        delete this.item.data;
        delete this.item.created_at;
        delete this.item.updated_at;
        delete this.item.estado;
        delete this.item.favoritos;
      }

      await this.$apollo.mutate({
        mutation,
        variables: {
          input: {
            ...this.item,
            id: this.item.id,
            keywords,
            foto_real,
            open_graph,
            galeria: galery,
          },
        },
      });

      this.itemSlug
        ? this.$toast.success("Producto actualizado")
        : this.$toast.success("Producto registrado");

      this.itemSlug ? this.$router.push({ name: "ProductsUpdate", params: {slug: this.itemSlug} }) : this.$router.push({ name: "ProductsList" });
    },
    showModalImages(type) {
      this.typeUploadImage = type;

      if (this.typeUploadImage === "galeria") {
        this.uploadMultiple = true;
        this.oldImages = [...this.item.galeria];
      } else if (this.typeUploadImage === "foto_real") {
        this.uploadMultiple = false;
        this.oldImages = [this.item.foto_real];
      } else if (this.typeUploadImage === "open_graph") {
        this.uploadMultiple = false;
        this.oldImages = [this.item.open_graph];
      }

      this.$bvModal.show("modal-images");
    },
    selectedImage(data) {
      if (this.typeUploadImage === "foto_real") {
        this.item.foto_real = data[0];
      } else if (this.typeUploadImage === "open_graph") {
        this.item.open_graph = data[0];
      } else if (this.typeUploadImage === "galeria") {
        this.item.galeria = data;
      }

      this.$bvModal.hide("modal-images");
    },
    removeImage(index) {
      this.item.galeria.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 280px;
    }
  }

  .dropzone-item {
    min-height: 200px;
    border: 1px dashed $info;
  }
}
</style>
