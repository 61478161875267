<template>
  <section class="animate__animated animate__fadeIn animate__faster">
    <Welcome v-if="currentUser" />

    <DashboardResume v-if="currentUser" />

    <section v-else>
      Welcome to Softaki
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import Welcome from '../components/Welcome.vue'
import DashboardResume from '../components/DashboardResume.vue'

export default {
    name: 'HomePage',
    components: {
      Welcome,
      DashboardResume
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/currentUser'
      })
    }
}
</script>

<style>

</style>