<template>
  <div class="modal-delete bg-white shadow-card">
    <div class="text-center mt-3">
      <img
        :src="item.info.url"
        alt=""
        class="main-image"
      />
    </div>

    <div class="text-center mt-3">
      <span class="icon">
        <i class="fas fa-trash"></i>
      </span>

      <h3 class="title mt-2">¿Está seguro de eliminar la imagen?</h3>

      <p class="description">El archivo será eliminado permanentemente.</p>
    </div>

    <div class="text-center">
      <button
        type="button"
        class="btn btn-success mr-3 py-1"
        @click="$emit('close')"
      >
        <i class="fas fa-chevron-left"></i>
        Cancelar
      </button>

      <button type="button" :disabled="loading" class="btn btn-danger py-1" @click="deleteItem()">
        {{ loading ? 'Eliminando...' : 'Confirmar' }}
      </button>
    </div>
  </div>
</template>

<script>
import DeleteImage from "../graphql/mutations/DeleteImage.gql";

export default {
  name: "ModalDeleteImage",
  data() {
    return {
      loading: false
    }
  },
  props: {
    item: Object
  },
  methods: {
    async deleteItem() {
      this.loading = true;

      let id = parseInt(this.item.info.id);

      await this.$apollo.mutate({
        mutation: DeleteImage,
        variables: {
          id,
        },
      });

      this.$swal({
        text: "La imagen ha sido eliminada correctamente",
        icon: "success",
      })

      this.$emit('deleted', this.item.index)

      this.loading = false
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.modal-delete {
  width: 95%;
  height: 95%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  .main-image {
    max-width: 100%;
    max-height: 200px;
    opacity: 0.7;
  }

  .icon {
    font-size: 2em;
    color: rgba($danger, 0.9);
  }

  .title {
    font-size: 1em;
    font-weight: 700;
  }

  .description {
    font-size: 0.9em;
    font-weight: 300;
  }
}
</style>