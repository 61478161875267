
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
 
 // introducir estilo
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import "highlight.js/styles/atom-one-dark.css";


Vue.use(VueQuillEditor)