import DistrictsView from "../DistrictsView.vue";
import DistrictsList from "../pages/DistrictsList.vue";

const routes = [
  {
    path: '/distritos',
    component: DistrictsView,
    children: [
      {
        path: '',
        name: 'DistrictsList',
        component: DistrictsList,
        meta: {
          auth: true
        }
      },
    ]
  }
]

export default routes
