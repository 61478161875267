<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">
        {{ itemId ? "Actualizar" : "Registrar" }} banco
      </h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <b-form @submit.prevent="submit">
              <b-form-group id="titulo" label="Nombre" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.titulo"
                  type="text"
                  placeholder="Ingresar titulo identificador"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.titulo.$error && !$v.item.titulo.required"
                />
              </b-form-group>

              <b-form-group id="numero" label="Número bancario" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.numero"
                  type="text"
                  placeholder="Ingresar número bancario"
                ></b-form-input>

                <FormError
                  text="El número bancario es requerido"
                  :show="$v.item.numero.$error && !$v.item.numero.required"
                />
              </b-form-group>

              <section class="mt-4">
                <div class="d-flex justify-content-center" v-if="item.imagen">
                  <img
                    :src="item.imagen.url"
                    :alt="item.imagen.titulo"
                    class="preview-image border"
                  />
                </div>

                <div class="text-center mt-3" v-if="itemId">
                  <a href="" @click.prevent="showModalImages()"
                    >Actualizar imagen</a
                  >
                </div>

                <div class="select-image text-center mt-3" v-else>
                  <a
                    href=""
                    class="btn btn-outline-info"
                    @click.prevent="showModalImages()"
                    >Asignar imagen</a
                  >
                </div>

                <FormError
                  text="La imagen es requerida"
                  :show="$v.item.imagen.$error && !$v.item.imagen.required"
                />
              </section>

              <div
                class="
                  form-group
                  d-flex
                  justify-content-between
                  align-items-center
                  text-center
                  mt-5
                "
              >
                <router-link :to="{ name: 'BanksList' }" class="text-danger">
                  <i class="fas fa-chevron-left"></i>
                  Cancelar
                </router-link>

                <button
                  type="submit"
                  class="btn btn-success px-4 ml-3"
                  :disabled="loadingAction"
                >
                  {{ itemId ? "Actualizar" : "Crear" }}
                </button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <ImageManagement :oldImages="oldImages" @selected="selectedImage($event)" />
  </section>
</template>

<script>
import GetBancoID from "../graphql/queries/GetBancoID.gql";
import CreateBancos from "../graphql/mutations/CreateBancos.gql";
import UpdateBancos from "../graphql/mutations/UpdateBancos.gql";

import { LINK_TYPE_imagen } from "@/utils/static-values.js";

import { Loading, ImageManagement, FormError } from "@/components";

import { required } from "vuelidate/lib/validators";

export default {
  name: "ProductsList",
  data() {
    return {
      items: [],
      loading: true,
      itemId: null,
      item: {
        titulo: null,
        numero: null,
        imagen: null
      },
      linkTypeimagen: LINK_TYPE_imagen,
      loadingAction: false,
      oldImages: []
    };
  },
  mounted() {
    this.init();
  },
  validations: {
    item: {
      titulo: {
        required,
      },
      numero: {
        required,
      },
      imagen: {
        required,
      }
    },
  },
  components: {
    Loading,
    ImageManagement,
    FormError,
  },
  methods: {
    init() {
      this.itemId = this.$route.params.id;

      if (this.itemId) {
        this.getItemId();
      } else {
        this.loading = false;
      }
    },
    async getItemId() {
      let response = await this.$apollo.query({
        query: GetBancoID,
        variables: {
          Id_banco: this.itemId,
        },
        fetchPolicy: 'no-cache'
      });

      let data = response.data.GetBancoID;

      if (data) {
        this.item = {
          ...data,
        };
      }

      this.loading = false;
    },
    showModalImages() {
      this.oldImages = [this.item.imagen]
      this.$bvModal.show('modal-images')
    },
    selectedImage(event) {
      this.item.imagen = event[0];
      this.$bvModal.hide("modal-images");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.loadingAction = true;

      let input = {
        ...this.item
      };

      input.imagen = input.imagen.id;

      let mutation = this.itemId ? UpdateBancos : CreateBancos;

      await this.$apollo.mutate({
        mutation,
        variables: {
          input,
        },
      });

      this.loadingAction = false;
      this.$router.push({ name: "BanksList" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.preview-image {
  max-width: 100%;
  max-height: 220px;
}
</style>