import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import HomeRoutes from '@/modules/home/routes'
import ImagesRoutes from '@/modules/images/routes'
import ProductsRoutes from '@/modules/products/routes'
// import OrdersRoutes from '@/modules/orders/routes'
import BannersRoutes from '@/modules/banners/routes'
import DistrictsRoutes from '@/modules/districts/routes'
import FinancesRoutes from '@/modules/finances/routes'
import VendorsRoutes from '@/modules/vendors/routes'

Vue.use(VueRouter)

let routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Index.vue')
  }
]

routes = routes.concat(HomeRoutes, ImagesRoutes, ProductsRoutes, BannersRoutes, DistrictsRoutes, FinancesRoutes, VendorsRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth
  const user = store.state.auth.user

  if(!user && requireAuth) {
    next('/login')
  } else if(to.name === 'Login' && user) {
    next('/')
  } else {
    next()
  }
})

export default router
