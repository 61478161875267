import VueCookies from 'vue-cookies'

import { token_name } from '@/utils/static-values'

export const auth = {
    namespaced: true,
    state: {
        user: VueCookies.get(token_name())
    },
    mutations: {
        SET_USER(state, data) {
            state.user = data
        }
    },
    getters: {
        currentUser(state) {
            return state.user
        }
    }
}