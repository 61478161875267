<template>
  <section
    class="
      products-list
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <h1 class="card-title">{{ itemSlug ? 'Actualizar categoría' : 'Registrar categoría' }}</h1>
    </div>

    <Loading v-if="loading" />

    <section id="main-container" class="mt-3 position-relative" v-else>
      <div class="row">
        <div class="col-md-8">
          <b-form @submit.prevent="onSubmit()">
            <div class="border-bottom pb-3">
              <b-form-group id="nombre" label="Nombre" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="item.nombre"
                  type="text"
                  placeholder="Ingresar nombre"
                ></b-form-input>

                <FormError
                  text="El nombre es requerido"
                  :show="$v.item.nombre.$error && !$v.item.nombre.required"
                />
              </b-form-group>

              <b-form-group
                id="descripcion"
                label="Descripción corta"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="item.descripcion"
                  placeholder="Resumen a mostrar"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="keywords"
                label="Palabras clave para SEO"
                label-for="keywords"
              >
                <b-form-tags
                  input-id="keywords"
                  v-model="item.keywords"
                ></b-form-tags>
              </b-form-group>
            </div>

            <div
              class="
                form-group
                d-flex
                justify-content-between
                align-items-center
                mt-3
              "
            >
              <a href="" @click.prevent="$router.back()" class="text-danger">
                <i class="fas fa-chevron-left"></i>
                Cancelar
              </a>

              <input
                type="submit"
                class="btn btn-success"
                :value="
                  itemSlug ? 'Actualizar categoría' : 'Registrar categoría'
                "
              />
            </div>
          </b-form>
        </div>

        <div class="col-md-4">
          <div>
            <label class="label">Imagen destacada</label>

            <section
              class="
                dropzone-item
                d-flex
                flex-column
                justify-content-center
                align-items-center
                mt-2
                pb-2
              "
            >
              <img
                :src="item.imagen.url"
                alt="Imagen destacada"
                class="img-fluid w-100 h-100"
                v-if="item.imagen"
              />

              <a
                href=""
                class="text-info font-weight-bold"
                @click.prevent="showModalImages('imagen')"
                >Seleccionar imagen</a
              >
            </section>

            <FormError
              text="La imagen destacada es requerida"
              :show="$v.item.imagen.$error && !$v.item.imagen.required"
            />
          </div>

          <div class="mt-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="label">Imagen secundaria</label>

              <b-form-checkbox
                v-model="showOpenGraphImage"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>

            <section
              class="
                dropzone-item
                d-flex
                flex-column
                justify-content-center
                align-items-center
                mt-2
                pb-2
              "
              v-if="showOpenGraphImage"
            >
              <img
                :src="item.open_graph.url"
                alt="Imagen destacada"
                class="img-fluid w-100 h-100"
                v-if="item.open_graph"
              />

              <a
                href=""
                class="text-info font-weight-bold"
                @click.prevent="showModalImages('open_graph')"
                >Seleccionar imagen</a
              >
            </section>
          </div>
        </div>
      </div>
    </section>

    <ImageManagement
      :multiple="uploadMultiple"
      :oldImages="oldImages"
      @selected="selected($event)"
    />
  </section>
</template>

<script>
import { PRODUCT_STATUS } from "@/utils/static-values.js";

import GetCategoriaSlug from "../graphql/queries/GetCategoriaSlug.gql";
import CreateCategoria from "@/modules/products/graphql/mutations/CreateCategoria.gql";
import UpdateCategoria from "../graphql/mutations/UpdateCategoria.gql";

import { required } from "vuelidate/lib/validators";

import { ImageManagement, FormError, Loading } from "@/components";

export default {
  name: "ProductsList",
  data() {
    return {
      productStatus: PRODUCT_STATUS,
      categories: [{ id: null, nombre: "Seleccione una categoría" }],
      itemSlug: null,
      item: {
        estado: 1,
        nombre: null,
        descripcion: null,
        keywords: null,
        imagen: null,
        open_graph: null,
      },
      loading: true,
      typeUploadImage: null,
      uploadMultiple: false,
      showOpenGraphImage: false,
      showGalleryImage: false,
      oldImages: [],
    };
  },
  mounted() {
    this.init();
  },
  components: {
    ImageManagement,
    FormError,
    Loading,
  },
  validations: {
    item: {
      estado: {
        required,
      },
      nombre: {
        required,
      },
      imagen: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.itemSlug = this.$route.params.slug;

      if (this.itemSlug) {
        this.getitemSlug();
      } else {
        this.loading = false;
      }
    },
    async getitemSlug() {
      this.loading = true;

      let response = await this.$apollo.query({
        query: GetCategoriaSlug,
        variables: {
          slug: this.itemSlug,
        },
        fetchPolicy: 'no-cache'
      });

      let data = response.data.GetCategoriaSlug;

      if (data) {
        this.item = {
          ...data,
        };

        if (this.item.keywords) {
          this.item.keywords = this.item.keywords.split(",");
        }

        if (this.item.open_graph) {
          this.showOpenGraphImage = true;
        }
      }

      this.loading = false;
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$error) return;

      let imagen = this.item.imagen ? this.item.imagen.id : null,
        open_graph = this.item.open_graph ? this.item.open_graph.id : null,
        keywords = this.item.keywords.join();

      let mutation = this.itemSlug ? UpdateCategoria : CreateCategoria;

      if (this.itemSlug) {
        delete this.item.data;
        delete this.item.created_at;
        delete this.item.updated_at;
        delete this.item.estado;
        delete this.item.favoritos;
      }

      await this.$apollo.mutate({
        mutation,
        variables: {
          input: {
            ...this.item,
            id: this.item.id,
            keywords,
            imagen,
            open_graph,
          },
        },
      });

      this.itemSlug
        ? this.$toast.success("Categoría actualizada")
        : this.$toast.success("Categoría registrada");
      this.$router.push({ name: "ProductsCategoryList" });
    },
    showModalImages(type) {
      this.typeUploadImage = type;

      if(this.typeUploadImage === 'imagen') {
        this.uploadMultiple = false;
        this.oldImages = [this.item.imagen]
      } else if(this.typeUploadImage === 'open_graph') {
        this.uploadMultiple = false;
        this.oldImages = [this.item.open_graph]
      }

      this.$bvModal.show("modal-images");
    },
    selected(data) {
      if (this.typeUploadImage === "imagen") {
        this.item.imagen = data[0];
      } else if (this.typeUploadImage === "open_graph") {
        this.item.open_graph = data[0];
      } else if (this.typeUploadImage === "galeria") {
        this.item.galeria = data;
      }

      this.$bvModal.hide("modal-images");
    },
    removeImage(index) {
      this.item.galeria.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.products-list {
  .table-name {
    width: 300px;

    @media (min-width: 768px) {
      width: 280px;
    }
  }

  .dropzone-item {
    min-height: 200px;
    border: 1px dashed $info;
  }
}
</style>