<template>
  <header
    class="
      header-app
      shadow-card
      px-3
      d-flex
      justify-content-between
      align-items-center
    "
  >
    <button @click="toggleSidebar" class="bg-transparent border-0">
      <span class="icon text-primary" v-if="!sidebarStatus">
        <i class="fas fa-toggle-off"></i>
      </span>

      <span class="icon text-primary" v-else>
        <i class="fas fa-toggle-on"></i>
      </span>
    </button>

    <nav>
      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        right
      >
        <template #button-content>
          <img :src="currentUser.urlPhoto ? currentUser.urlPhoto : '@/assets/images/global/user-avatar.png'" alt="Administrador" class="avatar">
          <span class="d-oinline-block font-weight-bold text-dark ml-2">{{ currentUser.name }} {{ currentUser.surnames }}</span>
        </template>
        <!-- <b-dropdown-item href="#">Mis datos</b-dropdown-item> -->
        <b-dropdown-item href="#" @click.prevent="logout()">
          <i class="fas fa-sign-out-alt mr-1"></i>
          Salir
        </b-dropdown-item>
      </b-dropdown>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex"

import { token_name } from '@/utils/static-values'

export default {
  name: "HeaderApp",
  props: {
    sidebarStatus: Boolean,
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    logout() {
      this.$cookies.remove(token_name())

      this.$store.commit('auth/SET_USER', null)

      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    })
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.header-app {
  width: 100%;
  height: $header-height;

  .icon {
    font-size: 1.3em;
  }

  .btn.dropdown-toggle {
    padding: 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
}
</style>