<template>
  <section
    id="main-container"
    class="
      images-upload
      main-content
      animate__animated animate__fadeIn animate__faster
    "
  >
    <div class="main-content-header d-flex">
      <div>
        <h1 class="card-title my-0">Subir imágenes</h1>
        <p class="my-0">
          Las imágenes serán guardadas para luego ser asignadas a productos o
          banners.
        </p>
      </div>
    </div>

    <Loading v-if="loading" />

    <section class="container mt-5 mt-md-4 px-1" v-else>
      <div class="row">
        <div class="col">
          <vue-dropzone
            ref="dropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-complete="uploadFiles"
          ></vue-dropzone>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <a href="" @click.prevent="$router.back()" class="text-danger">
            <i class="fas fa-chevron-left"></i>
            Cancelar
          </a>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import GetImagenes from "../graphql/queries/GetImagenes.gql";
import CreateImage from "../graphql/mutations/CreateImage.gql";

import { Loading } from "@/components";

export default {
  name: "ImagesPage",
  data() {
    return {
      loading: true,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
        dictDefaultMessage: `<p class='text-muted'><i class='fa fa-cloud-upload mr-2'></i> Arrastre imagenes o haga click aquí para seleccionar.</p>
          <p class="text-muted">Archivos permitidos: .jpg, .jpeg, .png</p>
          `,
      },
    };
  },
  mounted() {
    this.load();
  },
  components: {
    Loading,
  },
  methods: {
    async load() {
      let response = await this.$apollo.query({ query: GetImagenes, variables: {estado: ''} });

      this.images = response.data.GetImagenes;

      this.loading = false;
    },
    setSelectedImage(item, index) {
      this.selectedItem.info = item;
      this.selectedItem.index = index;

      this.showOptions = !this.showOptions;
    },
    removeImage(index) {
      this.images.splice(index, 1);

      this.showOptions = false;
    },
    uploadFiles(file) {
      this.$apollo
        .mutate({
          mutation: CreateImage,
          variables: {
            imagen: file,
            nombre: file.name,
          },
        })
        .then(() => {
          this.$refs.dropzone.removeFile(file);

          this.$toast.success("Imagen guardada");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.images-upload {
  .vue-dropzone {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgba($primary, 0.8);
  }
}
</style>