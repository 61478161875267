<template>
  <div
    class="error small text-danger mt-1 ml-1"
    v-if="show"
  >
    <i class="fas fa-exclamation mr-1"></i>
    {{ text }}
  </div>
</template>

<script>
export default {
    props: {
        text: String,
        show: Boolean
    }
};
</script>

<style>
</style>