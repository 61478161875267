import BannersView from "../BannersView.vue";
import BannersList from "../pages/BannersList.vue";
import BannersForm from "../pages/BannersForm.vue";

const routes = [
  {
    path: '/banner',
    component: BannersView,
    children: [
      {
        path: '',
        name: 'BannersList',
        component: BannersList,
        meta: {
          auth: true
        }
      },
      {
        path: 'update/:id',
        name: 'BannersUpdate',
        component: BannersForm,
        meta: {
          auth: true
        }
      },
      {
        path: 'create',
        name: 'BannersCreate',
        component: BannersForm,
        meta: {
          auth: true
        }
      }
    ]
  }
]

export default routes
