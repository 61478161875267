import VendorsView from "../VendorsView.vue";
import VendorsList from "../pages/VendorsList.vue";
import VendorForm from "../pages/VendorForm.vue";

const routes = [
  {
    path: '/vendedores',
    component: VendorsView,
    children: [
      {
        path: '',
        name: 'VendorsList',
        component: VendorsList,
        meta: {
          auth: true
        }
      },
      {
        path: 'update/:id',
        name: 'VendorsUpdate',
        component: VendorForm,
        meta: {
          auth: true
        }
      },
      {
        path: 'crear',
        name: 'VendorsCreate',
        component: VendorForm,
        meta: {
          auth: true
        }
      }
    ]
  }
]

export default routes
