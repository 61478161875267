import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import apolloProvider from '@/plugins/vue-apollo.js'

import '@/plugins/bootstrap'
import '@/plugins/vue-smoth-scroll.js'
import '@/plugins/dayjs.js'
import '@/plugins/vue-sweetalert2.js'
import '@/plugins/vue-dropzone.js'
import '@/plugins/vue-toast.js'
import '@/plugins/vuelidate.js'
import '@/plugins/vue-cookies.js'
import '@/plugins/vue-quill-editor.js'

import '@/assets/scss/app.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
