import ProductsView from "../ProductsView.vue";
import ProductsList from "../pages/ProductsList.vue";
import ProductsCategoryList from "../pages/ProductsCategoryList.vue";
import ProductsForm from "../pages/ProductsForm.vue";
import ProductsCategoryForm from "../pages/ProductsCategoryForm.vue";

const routes = [
  {
    path: '/productos',
    component: ProductsView,
    children: [
      {
        path: '',
        name: 'ProductsList',
        component: ProductsList,
        meta: {
          auth: true
        }
      },
      {
        path: 'update/:slug',
        name: 'ProductsUpdate',
        component: ProductsForm,
        meta: {
          auth: true
        }
      },
      {
        path: 'crear',
        name: 'ProductsCreate',
        component: ProductsForm,
        meta: {
          auth: true
        }
      },
      {
        path: 'categorias-productos',
        name: 'ProductsCategoryList',
        component: ProductsCategoryList,
        meta: {
          auth: true
        }
      },
      {
        path: 'categorias-productos/editar/:slug',
        name: 'ProductsCategoryEdit',
        component: ProductsCategoryForm,
        meta: {
          auth: true
        }
      },
      {
        path: 'categorias-productos/crear',
        name: 'ProductsCategoryCreate',
        component: ProductsCategoryForm,
        meta: {
          auth: true
        }
      }
    ]
  }
]

export default routes
