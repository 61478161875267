import ImagesView from "../ImagesView.vue";
import ImagesPage from "../pages/ImagesPage.vue";
import ImagesUpload from "../pages/ImagesUpload.vue";

const routes = [
  {
    path: '/imagenes',
    component: ImagesView,
    children: [
      {
        path: '',
        name: 'Images',
        component: ImagesPage,
        meta: {
          auth: true
        }
      },
      {
        path: '/upload',
        name: 'ImagesUpload',
        component: ImagesUpload,
        meta: {
          auth: true
        }
      }
    ]
  }
]

export default routes
