import HomeView from "../HomeView.vue";
import HomePage from "../pages/HomePage.vue";

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomePage,
        meta: {
          auth: true
        }
      },
    ],
  }
]

export default routes
